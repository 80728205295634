import { useState, useEffect } from "react";
import Header from "../../components/Header/Header";
import { FaIndianRupeeSign } from "react-icons/fa6";
import { IoIosCloseCircle } from "react-icons/io";
import { FaLocationArrow } from "react-icons/fa6";
function CapitalGain() {
  // const { isAuthenticated } = CheckAuth();
  const [openDropdown, setOpenDropdown] = useState(null); // Manages which dropdown is open
  const [selectedProduct, setSelectedProduct] = useState("Choose Product");
  const [selectedYear, setSelectedYear] = useState("Financial Year");
  const [selectedQuarter, setSelectedQuarter] = useState("Quarter");
  const [view, setView] = useState("");

  const toggleDropdown = (dropdown) => {
    setOpenDropdown(openDropdown === dropdown ? null : dropdown); // Toggle based on current open state
  };

  const handleOptionClick = (option, type) => {
    if (type === "product") setSelectedProduct(option);
    if (type === "year") setSelectedYear(option);
    if (type === "quarter") setSelectedQuarter(option);
    setOpenDropdown(null); // Close dropdown after selection
  };

  const handleClickOutside = (event) => {
    if (!event.target.closest(".dropdown-button")) {
      setOpenDropdown(null); // Close any open dropdown when clicking outside
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => document.removeEventListener("click", handleClickOutside);
  }, []);

  // open Breakups
  const toggleView = (viewName) => {
    if (view === viewName) {
      setView(""); // Close the view if it's already open
    } else {
      setView(viewName); // Open the selected view
    }
  };

  return (
    <div>
      {/* <Header />
      {!isAuthenticated ? (
        <PopUp1 />
      ) : ( */}
      <Header />
      <div className="pt-[110px]">
        <div className="max-w-[1800px] mx-auto">
          <div className="w-[90%] mx-auto text-black rounded-md">
            <div class="p-2">
              <h1 class="text-3xl font-extrabold leading-none tracking-tight text-gray-900 md:text-2xl lg:text-4xl text-center bg-white px-3 w-fit mx-auto">
                Capital <span className="text-[#0EA5E9]">Gain</span>
              </h1>
              <p className="text-sm text-center mb-4 mt-2">
                Capital gain is an economic concept defined as the profit earned
                on the sale of an asset <br />
                which has increased in value over the holding period
              </p>

              <div className="flex flex-wrap sm:justify-between md:justify-center gap-5 items-end rounded-md  py-3 border-y-2">
                {/* Choose Financial Year */}
                <div className="relative md:flex items-center gap-2 text-left w-full md:w-auto">
                  <p className="text-sm font-semibold w-full">
                    Financial Year <span className="text-red-600">*</span>
                  </p>
                  <button
                    type="button"
                    className={`dropdown-button flex items-center justify-between gap-2 px-2 py-1 bg-white rounded-md transition-all duration-300 focus:outline-none mt-1 w-full md:min-w-[160px] ${
                      openDropdown === "year"
                        ? "shadow-[0px_0px_0px_1.2px_#0EA5E9] "
                        : "shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)]"
                    }`}
                    onClick={() => toggleDropdown("year")}
                  >
                    <span className="text-sm font-semibold">
                      {selectedYear}
                    </span>
                    <svg
                      className={`w-5 h-5 text-gray-400 ${
                        openDropdown === "year" ? "rotate-180 duration-500" : ""
                      }`}
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" />
                    </svg>
                  </button>

                  {openDropdown === "year" && (
                    <div className="z-20 absolute md:top-9 w-[70%] md:w-44 right-0 bg-white rounded-md shadow-lg  border-b-[2px] border-[#0EA5E9] animate-fadeIn  ">
                      {["FY 2024-25", "FY 2023-24", "FY 2022-23"].map(
                        (option) => (
                          <a
                            href="#"
                            key={option}
                            className="block px-4 py-2 text-sm font-semibold hover:bg-[#DBEDF9] hover:text-[#0EA5E9] border-b"
                            onClick={() => handleOptionClick(option, "year")}
                          >
                            {option}
                          </a>
                        )
                      )}
                    </div>
                  )}
                </div>

                {/* Choose Quarter */}
                <div className="relative md:flex items-center gap-2 text-left ">
                  <p className="text-sm font-semibold">
                    Quarter <span className="text-red-600">*</span>
                  </p>
                  <button
                    type="button"
                    className={`dropdown-button flex items-center justify-between gap-2 px-2 py-1 bg-white rounded-md transition-all duration-300 focus:outline-none mt-1 min-w-[160px] ${
                      openDropdown === "quarter"
                        ? "shadow-[0px_0px_0px_1.2px_#0EA5E9]"
                        : "shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)]"
                    }`}
                    onClick={() => toggleDropdown("quarter")}
                  >
                    <span className="text-sm font-semibold">
                      {selectedQuarter}
                    </span>
                    <svg
                      className={`w-5 h-5 text-gray-400 ${
                        openDropdown === "quarter"
                          ? "rotate-180 duration-500"
                          : ""
                      }`}
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" />
                    </svg>
                  </button>

                  {openDropdown === "quarter" && (
                    <div className="absolute md:top-9 w-44 md:right-0 bg-white rounded-md shadow-lg z-20 border-b-[2px] border-[#0EA5E9] animate-fadeIn  ">
                      {[
                        "Q1 (Apr-June)",
                        "Q2 (Jul-Sept)",
                        "Q3 (Oct-Dec)",
                        "Q4 (Jan-Mar)",
                      ].map((option) => (
                        <a
                          href="#"
                          key={option}
                          className="block px-4 py-2 text-sm font-semibold hover:bg-[#DBEDF9] hover:text-[#0EA5E9] border-b"
                          onClick={() => handleOptionClick(option, "quarter")}
                        >
                          {option}
                        </a>
                      ))}
                    </div>
                  )}
                </div>

                <button class="px-3 py-1 text-sm rounded font-semibold bg-[#0EA5E9] text-white relative overflow-hidden group z-10 hover:text-white duration-1000 flex items-center gap-1">
                  <span class="absolute bg-[#347ab8] w-20 h-20 rounded-full group-hover:scale-100 scale-0 -z-10 -left-2 -top-10 group-hover:duration-500 duration-700 origin-center transform transition-all"></span>
                  <span class="absolute bg-[#3B71CA] w-20 h-20 -left-2 -top-10 rounded-full group-hover:scale-100 scale-0 -z-10 group-hover:duration-700 duration-500 origin-center transform transition-all"></span>
                  Check
                  <span>
                    <FaLocationArrow />
                  </span>
                </button>
              </div>

              {/* WALR Table */}
              <div class="py-4 overflow-x-auto">
                <h1 class=" px-2.5 py-1.5 w-[120px] md:w-[140px] flex justify-center gap-1 border-2 rounded-full border-[#0590F8] font-bold text-[#0590F8] leading-none tracking-tight text-sm md:text-[15px] sticky left-0">
                  <span className="">WALR </span> Bonds
                </h1>
                <div class="inline-block min-w-full shadow-md rounded-lg border-x border-b overflow-hidden mt-1">
                  <table class="min-w-full leading-normal ">
                    <thead>
                      <tr className="text-white font-bold text-xs md:text-sm bg-[#0590F8]">
                        <th class="px-2 py-2.5 border-b-2 border-gray-200  font-semibold tracking-wider">
                          Description
                        </th>
                        <th class="px-2 py-2.5 border-b-2 border-gray-200 text-center font-semibold tracking-wider">
                          Selling Price
                        </th>
                        <th class="px-2 py-2.5 border-b-2 border-gray-200 text-center font-semibold tracking-wider">
                          Deduction u/s 48
                        </th>
                        <th class="px-2 py-2.5 border-b-2 border-gray-200 text-center font-semibold tracking-wider">
                          Tax Rate
                        </th>
                        <th class="px-2 py-2.5 border-b-2 border-gray-200 text-center font-semibold tracking-wider">
                          Gain/Loss
                        </th>
                        <th class="px-2 py-2.5 border-b-2 border-gray-200 text-center font-semibold tracking-wider">
                          Breakups
                        </th>
                      </tr>
                    </thead>
                    <tbody className="font-semibold text-xs md:text-sm">
                      <tr>
                        <td class="px-2 py-2 border-b border-gray-200 bg-white text-[13px] md:text-sm min-w-[140px]">
                          <p class="text-gray-900 whitespace-no-wrap ">
                            Short Term Capital Gain
                          </p>
                        </td>
                        <td class="px-2 py-2 border-b border-gray-200 bg-white text-[13px] md:text-sm">
                          <p class="text-gray-900 whitespace-no-wrap text-center">
                            250
                          </p>
                        </td>
                        <td class="px-2 py-2 border-b border-gray-200 bg-white text-[13px] md:text-sm">
                          <p class="text-gray-900 whitespace-no-wrap text-center flex items-center justify-center">
                            <FaIndianRupeeSign className="text-black text-xs" />{" "}
                            100
                          </p>
                        </td>

                        <td class="px-2 py-2 border-b border-gray-200 bg-white text-[13px] md:text-sm">
                          <p class="text-gray-900 whitespace-no-wrap text-center">
                            12.5%
                          </p>
                        </td>
                        <td class="px-2 py-3 border-b border-gray-200 bg-white text-[13px] md:text-sm flex items-center justify-center gap-2">
                          <span class="relative px-3 py-1 font-semibold text-green-900 leading-tight flex items-center justify-center ">
                            <span
                              aria-hidden
                              class="absolute inset-0 bg-green-200 opacity-50 rounded-full"
                            ></span>
                            <FaIndianRupeeSign className="relative text-xs text-green-900" />
                            <span class="relative">500</span>
                          </span>
                        </td>
                        <td
                          onClick={() => toggleView("walr")}
                          class="px-2 py-2 border-b border-gray-200 bg-white text-[13px] md:text-sm cursor-pointer"
                        >
                          <span className="flex items-center justify-center">
                            <img
                              src="/images/details.png"
                              alt=""
                              className="w-8 h-8 hover:scale-95 duration-300 animate-pulse"
                            />
                          </span>
                        </td>
                      </tr>

                      <tr>
                        <td class="px-2 py-2 border-b border-gray-200 bg-white text-[13px] md:text-sm min-w-[140px]">
                          <p class="text-gray-900 whitespace-no-wrap ">
                            Long Term Capital Gain
                          </p>
                        </td>
                        <td class="px-2 py-2 border-b border-gray-200 bg-white text-[13px] md:text-sm">
                          <p class="text-gray-900 whitespace-no-wrap text-center">
                            250
                          </p>
                        </td>
                        <td class="px-2 py-2 border-b border-gray-200 bg-white text-[13px] md:text-sm">
                          <p class="text-gray-900 whitespace-no-wrap text-center flex items-center justify-center">
                            <FaIndianRupeeSign className="text-black text-xs" />{" "}
                            100
                          </p>
                        </td>

                        <td class="px-2 py-2 border-b border-gray-200 bg-white text-[13px] md:text-sm">
                          <p class="text-gray-900 whitespace-no-wrap text-center">
                            12.5%
                          </p>
                        </td>
                        <td class="px-2 py-3 border-b border-gray-200 bg-white text-[13px] md:text-sm flex items-center justify-center gap-2">
                          <span class="relative px-3 py-1 font-semibold text-red-900 leading-tight flex items-center justify-center ">
                            <span
                              aria-hidden
                              class="absolute inset-0 bg-red-200 opacity-50 rounded-full"
                            ></span>
                            <FaIndianRupeeSign className="relative text-xs text-red-900" />
                            <span class="relative">500</span>
                          </span>
                        </td>
                        <td
                          onClick={() => toggleView("walr")}
                          class="px-2 py-2 border-b border-gray-200 bg-white text-[13px] md:text-sm cursor-pointer"
                        >
                          <span className="flex items-center justify-center">
                            <img
                              src="/images/details.png"
                              alt=""
                              className="w-8 h-8 hover:scale-95 duration-300 animate-pulse"
                            />
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              {/* Residex Table */}
              <div class="py-4 overflow-x-auto">
                <h1 class=" px-2.5 py-1.5 w-[120px] md:w-[140px] flex justify-center gap-1 border-2 rounded-full border-[#009688] font-bold text-[#009688] leading-none tracking-tight text-sm md:text-[15px] sticky left-0">
                  <span className="">RESIDEX </span> Bonds
                </h1>
                <div class="inline-block min-w-full shadow-md rounded-lg border-x border-b overflow-hidden mt-1">
                  <table class="min-w-full leading-normal ">
                    <thead>
                      <tr className="text-white font-bold text-xs md:text-sm bg-[#009688]">
                        <th class="px-2 py-2.5 border-b-2 border-gray-200  font-semibold tracking-wider">
                          Description
                        </th>
                        <th class="px-2 py-2.5 border-b-2 border-gray-200 text-center font-semibold tracking-wider">
                          Selling Price
                        </th>
                        <th class="px-2 py-2.5 border-b-2 border-gray-200 text-center font-semibold tracking-wider">
                          Deduction u/s 48
                        </th>
                        <th class="px-2 py-2.5 border-b-2 border-gray-200 text-center font-semibold tracking-wider">
                          Tax Rate
                        </th>
                        <th class="px-2 py-2.5 border-b-2 border-gray-200 text-center font-semibold tracking-wider">
                          Gain/Loss
                        </th>
                        <th class="px-2 py-2.5 border-b-2 border-gray-200 text-center font-semibold tracking-wider">
                          Breakups
                        </th>
                      </tr>
                    </thead>
                    <tbody className="font-semibold text-xs md:text-sm">
                      <tr>
                        <td class="px-2 py-2 border-b border-gray-200 bg-white text-[13px] md:text-sm min-w-[140px]">
                          <p class="text-gray-900 whitespace-no-wrap ">
                            Short Term Capital Gain
                          </p>
                        </td>
                        <td class="px-2 py-2 border-b border-gray-200 bg-white text-[13px] md:text-sm">
                          <p class="text-gray-900 whitespace-no-wrap text-center">
                            250
                          </p>
                        </td>
                        <td class="px-2 py-2 border-b border-gray-200 bg-white text-[13px] md:text-sm">
                          <p class="text-gray-900 whitespace-no-wrap text-center flex items-center justify-center">
                            <FaIndianRupeeSign className="text-black text-xs" />{" "}
                            100
                          </p>
                        </td>

                        <td class="px-2 py-2 border-b border-gray-200 bg-white text-[13px] md:text-sm">
                          <p class="text-gray-900 whitespace-no-wrap text-center">
                            12.5%
                          </p>
                        </td>
                        <td class="px-2 py-3 border-b border-gray-200 bg-white text-[13px] md:text-sm flex items-center justify-center gap-2">
                          <span class="relative px-3 py-1 font-semibold text-green-900 leading-tight flex items-center justify-center ">
                            <span
                              aria-hidden
                              class="absolute inset-0 bg-green-200 opacity-50 rounded-full"
                            ></span>
                            <FaIndianRupeeSign className="relative text-xs text-green-900" />
                            <span class="relative">500</span>
                          </span>
                        </td>
                        <td
                          onClick={() => toggleView("walr")}
                          class="px-2 py-2 border-b border-gray-200 bg-white text-[13px] md:text-sm cursor-pointer"
                        >
                          <span className="flex items-center justify-center">
                            <img
                              src="/images/details.png"
                              alt=""
                              className="w-8 h-8 hover:scale-95 duration-300 animate-pulse"
                            />
                          </span>
                        </td>
                      </tr>

                      <tr>
                        <td class="px-2 py-2 border-b border-gray-200 bg-white text-[13px] md:text-sm min-w-[140px]">
                          <p class="text-gray-900 whitespace-no-wrap ">
                            Long Term Capital Gain
                          </p>
                        </td>
                        <td class="px-2 py-2 border-b border-gray-200 bg-white text-[13px] md:text-sm">
                          <p class="text-gray-900 whitespace-no-wrap text-center">
                            250
                          </p>
                        </td>
                        <td class="px-2 py-2 border-b border-gray-200 bg-white text-[13px] md:text-sm">
                          <p class="text-gray-900 whitespace-no-wrap text-center flex items-center justify-center">
                            <FaIndianRupeeSign className="text-black text-xs" />{" "}
                            100
                          </p>
                        </td>

                        <td class="px-2 py-2 border-b border-gray-200 bg-white text-[13px] md:text-sm">
                          <p class="text-gray-900 whitespace-no-wrap text-center">
                            12.5%
                          </p>
                        </td>
                        <td class="px-2 py-3 border-b border-gray-200 bg-white text-[13px] md:text-sm flex items-center justify-center gap-2">
                          <span class="relative px-3 py-1 font-semibold text-red-900 leading-tight flex items-center justify-center ">
                            <span
                              aria-hidden
                              class="absolute inset-0 bg-red-200 opacity-50 rounded-full"
                            ></span>
                            <FaIndianRupeeSign className="relative text-xs text-red-900" />
                            <span class="relative">500</span>
                          </span>
                        </td>
                        <td
                          onClick={() => toggleView("walr")}
                          class="px-2 py-2 border-b border-gray-200 bg-white text-[13px] md:text-sm cursor-pointer"
                        >
                          <span className="flex items-center justify-center">
                            <img
                              src="/images/details.png"
                              alt=""
                              className="w-8 h-8 hover:scale-95 duration-300 animate-pulse"
                            />
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          {view === "walr" && (
            <div class="py-4  demo2-container">
              <div class="inline-block w-[95%] md:w-[70%] xl:w-[45%] shadow-md rounded-lg border-x border-b overflow-x-scroll pop-up-animation bg-white">
                <div className="flex items-center justify-between pr-3 min-w-[460px]">
                  <p className="font-semibold p-2.5 text-black flex items-center gap-2">
                    <span className="">
                      <img
                        src="/images/details.png"
                        alt=""
                        className="w-6 h-6"
                      />
                    </span>
                    Detailed Breakups
                  </p>
                  <IoIosCloseCircle
                    className="cursor-pointer text-lg text-red-700"
                    onClick={() => {
                      setView("");
                    }}
                  />
                </div>
                <table class="min-w-[460px] md:min-w-full overflow-x-scroll leading-normal ">
                  <thead className="">
                    <tr className="text-white font-bold text-xs md:text-sm bg-[#0590F8]">
                      <th class="pr-2 pl-5 py-2.5 border-b-2 border-gray-200  font-semibold tracking-wider">
                        Series
                      </th>
                      <th class="px-2 py-2.5 border-b-2 border-gray-200 text-center font-semibold tracking-wider">
                        Net Sold
                      </th>
                      <th class="px-2 py-2.5 border-b-2 border-gray-200 text-center font-semibold tracking-wider">
                        Acquisition Price
                      </th>
                      <th class="px-2 py-2.5 border-b-2 border-gray-200 text-center font-semibold tracking-wider">
                        Time Held
                      </th>
                    </tr>
                  </thead>
                  <tbody className="font-semibold text-xs md:text-sm overflow-y-scroll">
                    <tr>
                      <td class="pr-2 pl-5 py-2 border-b border-gray-200 bg-white text-[13px] md:text-sm">
                        <p class="text-gray-900 whitespace-no-wrap ">Q1 FY21</p>
                      </td>
                      <td class="px-2 py-2 border-b border-gray-200 bg-white text-[13px] md:text-sm">
                        <p class="text-gray-900 whitespace-no-wrap text-center flex items-center justify-center">
                          <FaIndianRupeeSign className="text-black text-xs" />{" "}
                          250
                        </p>
                      </td>
                      
                      <td class="px-2 py-2 border-b border-gray-200 bg-white text-[13px] md:text-sm">
                        <p class="text-gray-900 whitespace-no-wrap text-center flex items-center justify-center">
                          <FaIndianRupeeSign className="text-black text-xs" />{" "}
                          1002.1
                        </p>
                      </td>
                      <td class="px-2 py-2 border-b border-gray-200 bg-white text-[13px] md:text-sm">
                        <p class="text-gray-900 whitespace-no-wrap text-center flex items-center justify-center">
                          500 days
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td class="pr-2 pl-5 py-2 border-b border-gray-200 bg-white text-[13px] md:text-sm">
                        <p class="text-gray-900 whitespace-no-wrap ">Q2 FY21</p>
                      </td>
                      <td class="px-2 py-2 border-b border-gray-200 bg-white text-[13px] md:text-sm">
                        <p class="text-gray-900 whitespace-no-wrap text-center flex items-center justify-center">
                          <FaIndianRupeeSign className="text-black text-xs" />{" "}
                          350
                        </p>
                      </td>
                      
                      <td class="px-2 py-2 border-b border-gray-200 bg-white text-[13px] md:text-sm">
                        <p class="text-gray-900 whitespace-no-wrap text-center flex items-center justify-center">
                          <FaIndianRupeeSign className="text-black text-xs" />{" "}
                         997.8
                        </p>
                      </td>
                      <td class="px-2 py-2 border-b border-gray-200 bg-white text-[13px] md:text-sm">
                        <p class="text-gray-900 whitespace-no-wrap text-center flex items-center justify-center">
                          126 days
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td class="pr-2 pl-5 py-2 border-b border-gray-200 bg-white text-[13px] md:text-sm">
                        <p class="text-gray-900 whitespace-no-wrap ">Q3 FY21</p>
                      </td>
                      <td class="px-2 py-2 border-b border-gray-200 bg-white text-[13px] md:text-sm">
                        <p class="text-gray-900 whitespace-no-wrap text-center flex items-center justify-center">
                          <FaIndianRupeeSign className="text-black text-xs" />{" "}
                          450
                        </p>
                      </td>
                      
                      <td class="px-2 py-2 border-b border-gray-200 bg-white text-[13px] md:text-sm">
                        <p class="text-gray-900 whitespace-no-wrap text-center flex items-center justify-center">
                          <FaIndianRupeeSign className="text-black text-xs" />{" "}
                          15425.5
                        </p>
                      </td>
                      <td class="px-2 py-2 border-b border-gray-200 bg-white text-[13px] md:text-sm">
                        <p class="text-gray-900 whitespace-no-wrap text-center flex items-center justify-center">
                          130 days
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td class="pr-2 pl-5 py-2 border-b border-gray-200 bg-white text-[13px] md:text-sm">
                        <p class="text-gray-900 whitespace-no-wrap ">Q4 FY21</p>
                      </td>
                      <td class="px-2 py-2 border-b border-gray-200 bg-white text-[13px] md:text-sm">
                        <p class="text-gray-900 whitespace-no-wrap text-center flex items-center justify-center">
                          <FaIndianRupeeSign className="text-black text-xs" />{" "}
                          250
                        </p>
                      </td>
                      
                      <td class="px-2 py-2 border-b border-gray-200 bg-white text-[13px] md:text-sm">
                        <p class="text-gray-900 whitespace-no-wrap text-center flex items-center justify-center">
                          <FaIndianRupeeSign className="text-black text-xs" />{" "}
                          150
                        </p>
                      </td>
                      <td class="px-2 py-2 border-b border-gray-200 bg-white text-[13px] md:text-sm">
                        <p class="text-gray-900 whitespace-no-wrap text-center flex items-center justify-center">
                          250 days
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
      </div>
      {/* )} */}
    </div>
  );
}

export default CapitalGain;
