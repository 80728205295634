import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PopUp1 from "../../components/PopUp1/PopUp1";
import "./Pricing.css";
import WalrHistory from "./WalrHistory.jsx";
import ResidexHistory from "./ResidexHistory.jsx";
import AgentInfoApp from "../../API/AgentInfoApi";
import CheckAuth from "../../components/ServiceFunctions/CheckAuth";
import UserData from "../../components/ServiceFunctions/UserData";
import KycPopUp from "../../components/PopUp1/PopUp2";
import Loader2 from "../../components/Loaders/Loader2";
import Header from "../../components/Header/Header";
import Form16A from "./Form16A.jsx";
import secureLocalStorage from "react-secure-storage";
import { RiExchangeLine } from "react-icons/ri";
import { FaWpforms } from "react-icons/fa6";
import { RiExchangeFundsFill } from "react-icons/ri";
import { ImBullhorn } from "react-icons/im";
import { TbCoinRupeeFilled } from "react-icons/tb";
import { GoKebabHorizontal } from "react-icons/go";
import { IoArrowUpCircle } from "react-icons/io5";
import { IoIosCloseCircle } from "react-icons/io";
import { LiaFileContractSolid } from "react-icons/lia";
import { SiHiveBlockchain } from "react-icons/si";
import { TiStarFullOutline } from "react-icons/ti";
import { IoIosMail } from "react-icons/io";
import { BiSolidPhoneCall } from "react-icons/bi";
import { IoCloseCircle } from "react-icons/io5";
import FAQFee from "../../pages/Pricing/FAQFee.jsx";

function Pricing() {
  const { page } = useParams();
  const { isAuthenticated, email, phone, pan, kycStatus, walr, residex } =
    CheckAuth();
  const { loader, userKycData } = UserData({
    isAuthenticated,
    email,
    phone,
    pan,
    formName: "",
  });
  const navigate = useNavigate();
  const [totalAmount, setTotalAmount] = useState(0);
  const [feedBack, setFeedBack] = useState("");
  const storedData = secureLocalStorage.getItem("blockchainData");
  const blockchainData = JSON.parse(storedData);
  const storedData2 = secureLocalStorage.getItem("couponData");
  const couponData = JSON.parse(storedData2);
  const storedData3 = secureLocalStorage.getItem("tempData");
  const tempData = JSON.parse(storedData3);

  var totalWALRFee = 0;
  var totalRESIDEXFee = 0;
  // Extract and sort date keys
  const sortedDates = couponData
    ? Object.keys(couponData)
        .filter((key) => key !== "pan")
        .sort((a, b) => {
          return new Date(b) - new Date(a);
        })
    : [];

  // Create a new sorted object
  const sortedCouponData = { pan: couponData?.pan };
  sortedDates?.forEach((date) => {
    sortedCouponData[date] = couponData[date];
  });

  // Extract managementfee entries
  for (const date in sortedCouponData) {
    if (
      sortedCouponData[date]?.WALR &&
      sortedCouponData[date]?.WALR?.Management !== undefined
    ) {
      totalWALRFee += sortedCouponData[date]?.WALR?.Management;
    }
    if (
      sortedCouponData[date]?.RESIDEX &&
      sortedCouponData[date]?.RESIDEX?.Management !== undefined
    ) {
      totalRESIDEXFee += sortedCouponData[date]?.RESIDEX?.Management;
    }
  }

  const getAgentname = (code) => {
    const agent = AgentInfoApp.find(
      (data) => data.AgentCode === parseInt(code)
    );
    return agent ? agent.AgentName : "No Manager";
  };

  const getAgentPhone = (code) => {
    const agent = AgentInfoApp.find(
      (data) => data.AgentCode === parseInt(code)
    );
    return agent ? agent.mobile : "0000000000";
  };

  const getAgentMail = (code) => {
    const agent = AgentInfoApp.find(
      (data) => data.AgentCode === parseInt(code)
    );
    return agent ? agent.email : "-------------------";
  };

  //
  function formatDate(dateString) {
    const date = new Date(dateString);
    const options = { month: "long", year: "numeric" };
    const formattedDate = date?.toLocaleDateString("en-IN", options);
    const day = date.getDate();
    let suffix = "th";
    if (day === 1 || day === 21 || day === 31) {
      suffix = "st";
    } else if (day === 2 || day === 22) {
      suffix = "nd";
    } else if (day === 3 || day === 23) {
      suffix = "rd";
    }
    return `${day}${suffix} ${formattedDate}`;
  }

  //
  let onboardingUnits = 0;
  // First, check blockchainData for "Onboarding"
  const blockchainOnboarding = blockchainData?.find(
    (data) => data["Security"] === "Onboarding"
  );
  // If it's found, use its Units. Otherwise, check tempData.
  if (blockchainOnboarding) {
    onboardingUnits = blockchainOnboarding?.["Units"] || 0;
  } else {
    const tempOnboarding = tempData?.find(
      (data) => data["Security"] === "Onboarding"
    );
    onboardingUnits = tempOnboarding?.["Units"] || 0;
  }

  useEffect(() => {
    if (pan) {
      // Initial Investment Calculation
      var amount = 0;
      var relationshipDate = new Date(userKycData?.["Relationship_start"]);
      blockchainData?.forEach((data) => {
        var transactionDate = new Date(data["Timestamp"]);
        var timeDiff = transactionDate.getTime() - relationshipDate.getTime();
        var diffInYears = timeDiff / (1000 * 3600 * 24 * 365);
        if (
          diffInYears <= 1 &&
          data["Buyer"] === pan &&
          (data["Security"].includes("WALR") ||
            data["Security"].includes("RESIDEX"))
        ) {
          amount += data["Units"] * data["Price_per_unit"];
        }
      });
      setTotalAmount(amount);
      // Initial Investment Calculation
    }
  }, [email, phone, pan, totalAmount]);

  // Images for feedback
  const [selectedStar, setSelectedStar] = useState(0);
  const images = [
    "/images/emoji0.png",
    "/images/emoji1.png",
    "/images/emoji2.png",
    "/images/emoji3.png",
    "/images/emoji4.png",
    "/images/emoji5.png",
  ];
  const [contactPopUp, setContactPopUp] = useState(false);

  const PricingContent = [
    {
      id: 1,
      title: "Onboard Fee",
      name: "Onboard Fee",
      link: "Onboard",
    },
    {
      id: 2,
      title: "Form 16A(TDS)",
      name: "Form 16A(TDS)",
      link: "form16A",
    },
    {
      id: 3,
      title: "Expense Ratio",
      name: "Management WALR",
      link: "manageWALR",
    },
    {
      id: 4,
      title: "Expense Ratio",
      name: "Management RESIDEX",
      link: "manageRESIDEX",
    },
    {
      id: 5,
      title: "Advisory Fee",
      name: "Advisory Fee",
      link: "Advisory",
    },
    {
      id: 6,
      title: "Gas Charges",
      name: "Gas Charges",
      link: "Gascharge",
    },
  ];

  // Grouping buttons by title
  const groupedButtons = PricingContent.reduce((acc, card) => {
    if (!acc[card.title]) {
      acc[card.title] = [];
    }
    acc[card.title].push(card);
    return acc;
  }, {});

  // Icons
  const getIconForTitle = (title) => {
    switch (title) {
      case "Onboard Fee":
        return <ImBullhorn className="icon-size" />;
      case "Management WALR":
        return <RiExchangeLine className="icon-size" />;
      case "Management RESIDEX":
        return <RiExchangeFundsFill className="icon-size" />;
      case "Form 16A(TDS)":
        return <FaWpforms className="icon-size" />;
      case "Advisory Fee":
        return <LiaFileContractSolid className="icon-size" />;
      case "Gas Charges":
        return <SiHiveBlockchain className="icon-size" />;
      default:
        return null;
    }
  };

  const [iconChange, setIconChange] = useState(false);

  const handleAgentFeedback = () => {
    if (selectedStar === 0) {
      alert("Please give your ratings to agent.");
    } else if (feedBack === "" || feedBack === " ") {
      alert("Please give your feedback to agent.");
    } else {
      fetch(`https://api-scdndestates.com/api/agent-feedback/`, {
        method: "POST",
        body: JSON.stringify({
          pan,
          code:
            userKycData === null ||
            userKycData["agencyCode"] === null ||
            userKycData["agencyCode"] === undefined
              ? ""
              : userKycData["agencyCode"],
          agent_email: getAgentMail(
            userKycData === null ||
              userKycData["agencyCode"] === null ||
              userKycData["agencyCode"] === undefined
              ? ""
              : userKycData["agencyCode"]
          ),
          star: selectedStar,
          feedBack,
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Failed to fetch data");
          }
          return response.json();
        })
        .then((data) => {
          if (data.message === "Success") {
            alert("Thank you for you Feedback.");
            setSelectedStar(0);
            setFeedBack("");
          } else {
            alert("Please try again!");
          }
        })
        .catch((error) => {
          // console.error("Error: ", error);
        });
    }
  };

  const [hoveredStar, setHoveredStar] = useState(0);
  const [animate, setAnimate] = useState(false);

  const handleStarClick = (index) => {
    setSelectedStar(index + 1);
    setAnimate(true);
    setTimeout(() => setAnimate(false), 500); // Reset animation after it completes
  };

  return (
    <div>
      <Header />
      {loader ? (
        <Loader2 />
      ) : !isAuthenticated ? (
        <PopUp1 />
      ) : kycStatus !== 85 ? (
        <KycPopUp />
      ) : (
        <>
          <div className="mx-auto max-w-[1800px] h-full">
            <div className="w-[90%] mx-auto lg:flex flex-row justify-start gap-4 pt-10 md:pt-20">
              <div className="col-md-2 col-lg-2 col-sm-12 py-2 d-flex flex-col gap-3 w-full lg:w-auto">
                <div className=" align-left border border-[#D8E5F9] rounded-xl px-3 py-2 hidden-768 pb-3 z-0">
                  <div className="custodian-buttons">
                    {Object.entries(groupedButtons).map(
                      ([title, cards], index) => (
                        <div key={index} className="flex flex-col gap-2">
                          <div
                            key={index}
                            className="side-sub-title flex justify-center"
                          ></div>
                          {cards.map((card) => (
                            <div
                              key={index}
                              onClick={() => navigate(`/pricing/${card.link}`)}
                            >
                              <button
                                key={index}
                                className={`custodian-button bg-gray-100 border flex gap-2 items-center py-2.5 px-3 ${
                                  card.link === page ? "cust-active" : ""
                                }`}
                                onClick={() =>
                                  navigate(`/pricing/${card.link}`)
                                }
                              >
                                {getIconForTitle(card.name)}&nbsp;
                                {card.name}
                              </button>
                            </div>
                          ))}
                        </div>
                      )
                    )}
                  </div>
                </div>
              </div>

              {/* Bottom sidebar */}
              <div className="fixed bottom-0 left-0 bg-[#3B71CA] p-2.5 md:px-20 w-full inline-flex text-[10px] justify-between items-end z-10 font-bold lg:hidden">
                {Object.entries(groupedButtons).map(([title, cards], index) => (
                  <>
                    {title !== "Expense Ratio" ? (
                      <>
                        {cards.map((card) => (
                          <>
                            <div
                              key={index}
                              className="flex flex-col justify-center items-center w-[60px] h-[60px] gap-1 text-[#D8E5F9] hover:text-white"
                              onClick={() => navigate(`/pricing/${card.link}`)}
                            >
                              <span
                                className={`bg-[#3b71ca] rounded-full h-fit flex flex-col items-center p-1.5 ${
                                  card.link === page && iconChange === false
                                    ? "nav-active"
                                    : "top-0 pb-2.5"
                                }`}
                              >
                                <span className="text-[10px] p-1 rounded-full top-0 text-center ">
                                  {getIconForTitle(card.name)}
                                </span>
                                <p className="text-[10px] text-center leading-3">
                                  {card.name.split(" ")[0]} <br />
                                  {card.name.split(" ")[1]}
                                </p>
                              </span>
                            </div>
                          </>
                        ))}
                      </>
                    ) : (
                      <>
                        <>
                          <div
                            className="flex flex-col justify-center items-center text-black w-[60px] h-[60px] bg-[#D8E5F9] rounded-2xl border-[#386BC0] border-2 duration-200"
                            onClick={() => {
                              setIconChange(!iconChange);
                            }}
                          >
                            {!iconChange ? (
                              <GoKebabHorizontal className="font-bold text-lg" />
                            ) : (
                              <IoArrowUpCircle className="font-bold text-lg" />
                            )}
                            {/* <GoKebabHorizontal className="font-bold text-xl" /> */}
                            <p className="text-center text-[10px]">Expense Ratio</p>
                          </div>
                          {/*  */}
                        </>
                      </>
                    )}
                  </>
                ))}
              </div>

              {iconChange && (
                <div className="demo3-container">
                  <div
                    className={`min-h-[230px] min-w-[250px] p-4 flex flex-col rounded-xl border border-[#3B71CA] bg-white shadow ${
                      !iconChange ? "" : "animate-scaleUp"
                    }`}
                  >
                    {Object.entries(groupedButtons).map(
                      ([title, cards], index) => (
                        <>
                          {title === "Expense Ratio" && (
                            <>
                              <div
                                key={index}
                                className="font-bold flex justify-between items-center"
                              >
                                <p className="">Expense Ratio</p>
                                <IoIosCloseCircle
                                  onClick={() => setIconChange(!iconChange)}
                                />
                              </div>
                              <hr className="mt-2" />
                              <div className="flex text-xs gap-5 font-bold mt-4">
                                {cards.map((card) => (
                                  <div
                                    className="flex justify-center flex-col items-center"
                                    onClick={() => {
                                      navigate(`/pricing/${card.link}`);
                                      setIconChange(false);
                                    }}
                                  >
                                    <i>{getIconForTitle(card.name)}</i>
                                    <p className="text-center">
                                      {card.name.split(" ")[0]} <br />
                                      {card.name.split(" ")[1]} <br />
                                      {card.name.split(" ")[2]}
                                    </p>
                                  </div>
                                ))}
                              </div>
                            </>
                          )}
                        </>
                      )
                    )}
                  </div>
                </div>
              )}

              {page === "Onboard" ? (
                <>
                  <div className="w-full lg:max-w-[700px] xl:max-w-[900px] py-2 table-media m-0">
                    <div className="">
                      <div className="relative text-black pt-4 pb-3 border rounded-lg max-md:mt-6 md:mt-4 ">
                        <h2 className="absolute  border rounded-lg -top-3 lg:-top-4 left-3 md:left-4 bg-white px-2.5 text-base lg:text-lg font-semibold">
                          Fee Structure
                        </h2>
                        {/* Graph div */}
                        <div className="row m-0 p-0 pt-2">
                          <span
                            className="w-[30%] lg:w-[15%] text-[15px]"
                            style={{ backgroundColor: "#e4a11b9c" }}
                          >
                            10L - 20L
                          </span>
                          <span className="w-[25%] lg:w-[10%] text-[15px]">
                            0.5%
                          </span>
                          <hr className="mb-1 m-0 d-inline-block text-light" />
                          <span
                            className="w-[35%] lg:w-[20%] "
                            style={{ backgroundColor: "#ff4f0087" }}
                          >
                            20L - 50L
                          </span>
                          <span className="w-[25%] lg:w-[10%] text-[15px]">
                            0.4%
                          </span>
                          <hr className="mb-1 m-0 d-inline-block text-light" />
                          <span
                            className="w-[50%] lg:w-[30%] text-[15px]"
                            style={{ backgroundColor: "#dc4c64ba" }}
                          >
                            50L - 1Cr
                          </span>
                          <span className="w-[25%] lg:w-[10%] text-[15px]">
                            0.3%
                          </span>
                          <hr className="mb-1 m-0 d-inline-block text-light" />
                          <span
                            className="w-[65%] lg:w-[40%] text-[15px]"
                            style={{ backgroundColor: "#dc35b28c" }}
                          >
                            1Cr - 5Cr
                          </span>
                          <span className="w-[25%] lg:w-[10%] text-[15px]">
                            0.2%
                          </span>
                          <hr className="mb-1 m-0 d-inline-block text-light" />
                          <span
                            className="w-[80%] lg:w-[50%] text-[15px]"
                            style={{ backgroundColor: "#3b71ca7a" }}
                          >
                            &gt;5 Crore
                          </span>
                          <span className="w-[20%] lg:w-[10%] px-2 text-[15px]">
                            0.1%
                          </span>
                        </div>
                      </div>

                      <div className="flex flex-wrap justify-between items-start text-black border h-80 md:h-[160px] px-2.5 py-4 xl:pl-4 xl:pr-4 relative mt-[28px] rounded-lg">
                        <p className="absolute left-3 md:left-4 -top-4 border rounded-lg text-black bg-white px-2.5 py-1 font-semibold text-sm md:text-base">
                          Relationship date :{" "}
                          <span className="font-bold">
                            {userKycData === null ||
                            userKycData["Relationship_start"] === null ||
                            userKycData["Relationship_start"] === undefined
                              ? ""
                              : formatDate(userKycData["Relationship_start"])}
                          </span>
                        </p>
                        {/* Container-1 */}
                        <div className="relative w-full md:w-[320px] lg:w-[311px] xl:w-[350px]">
                          <div className="text-xs bg-[#3B71CA] text-white font-semibold rounded-t-xl ">
                            <p className="p-1.5 text-center">
                              Total purchase of DeAs through Primary Market
                              throughout the first year
                            </p>
                          </div>
                          <div className="p-3 font-semibold lg:text-base 2xl:text-lg rounded-lg flex justify-between items-center gap-1 bg-[#F0F8FD] cursor-pointer shadow-md hover:mt-[38px] duration-200 w-full  absolute top-0">
                            <div className="text-[#0C2A75]">
                              <h2>Initial Investment</h2>
                              <h2 className="font-bold mt-2 lg:text-lg xl:text-xl flex items-center gap-1">
                                <TbCoinRupeeFilled />{" "}
                                {totalAmount?.toLocaleString("en-IN", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                              </h2>
                            </div>
                            <div className="pl-5 ">
                              <img
                                src="/images/initial-investment.png"
                                alt=""
                                className="w-[55px] xl:w-[60px]"
                              />
                            </div>
                          </div>
                        </div>
                        {/* Container-2 */}
                        <div className="relative w-full md:w-[320px] lg:w-[311px] xl:w-[350px] top-2 md:top-0">
                          <div className="text-xs bg-[#3B71CA] text-white font-semibold rounded-t-xl">
                            <p className="p-1.5 text-center">
                              Charged only once in your lifetime as placement
                              and promotional expenses
                            </p>
                          </div>
                          <div className="p-3 font-semibold lg:text-base 2xl:text-lg rounded-lg flex justify-between items-center gap-1 bg-[#F0F8FD] cursor-pointer shadow-md hover:mt-[38px] duration-200 w-full absolute top-0">
                            <div className="text-[#0C2A75]">
                              <h2>Onboarding Fee</h2>
                              <h2 className="font-bold mt-2 lg:text-lg xl:text-xl flex items-center gap-1">
                                <TbCoinRupeeFilled />
                                {onboardingUnits?.toLocaleString("en-IN", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                              </h2>
                            </div>
                            <div className="pl-5 ">
                              <img
                                src="/images/taxes.png"
                                alt=""
                                className="w-12 xl:w-14"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Relationship manager */}
                    <div className="border mt-3 md:mt-28 p-3 rounded-lg shadow-md">
                      <div className="flex justify-between items-center py-1 px-2">
                        <p className="text-base font-semibold text-black">
                          Relationship Manager: {""}
                          <span className="block lg:inline font-bold">
                            {getAgentname(
                              userKycData === null ||
                                userKycData["agencyCode"] === null ||
                                userKycData["agencyCode"] === undefined
                                ? ""
                                : userKycData["agencyCode"]
                            )}
                          </span>
                        </p>

                        <button
                          className="bg-[#3B71CA] text-white px-2.5 py-0.5 rounded-full text-xs 2xl:text-sm font-semibold"
                          onClick={() => setContactPopUp(!contactPopUp)}
                        >
                          Contact
                        </button>
                      </div>
                      <hr className="my-2 mx-2" />
                      <div className="px-3 py-4 border relative mt-4 rounded-lg mx-2">
                        <h2 className="absolute -top-2.5 bg-white px-2 font-bold text-black">
                          Feedback
                        </h2>
                        {/* feedback Section */}
                        <div className="flex justify-between flex-wrap gap-2">
                          {/* Feedback Animation */}
                          <div className="w-full md:w-60 h-40 border rounded-lg flex flex-col items-center justify-evenly">
                            <img
                              src={images[selectedStar]}
                              alt="emoji"
                              loading="lazy"
                              className={`w-20 ${
                                animate ? "animated-slide-downfeedback" : ""
                              }`}
                            />
                            <div className="text-3xl text-[#E3E3E3] flex items-center justify-center gap-1.5">
                              {[...Array(5)].map((_, index) => (
                                <TiStarFullOutline
                                  key={index}
                                  className={`cursor-pointer duration-200 ${
                                    index < selectedStar
                                      ? "text-[#FCD93A]"
                                      : index < hoveredStar
                                      ? "text-[#F4C534]"
                                      : "hover:text-[#F4C534]"
                                  }`}
                                  onClick={() => handleStarClick(index)}
                                  onMouseEnter={() => setHoveredStar(index + 1)}
                                  onMouseLeave={() => setHoveredStar(0)}
                                />
                              ))}
                            </div>
                          </div>
                          <div className="flex flex-col items-start w-full md:w-[60%]">
                            <p className="font-semibold text-black text-base">
                              How can we help you?
                            </p>
                            <textarea
                              value={feedBack}
                              onChange={(e) => setFeedBack(e.target.value)}
                              placeholder="type here..."
                              className="border rounded-md px-2 py-2 h-full mt-1 w-full text-gray-700"
                            ></textarea>
                            <button
                              className="bg-[#3B71CA] text-white px-3 py-1 rounded-full text-xs font-semibold mt-2 w-fit"
                              onClick={handleAgentFeedback}
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* Contact Popup */}
                    {contactPopUp && (
                      <div className="popup-container">
                        <div className="flex flex-col justify-center items-center relative bg-white border p-4 rounded-lg pop-up-animation w-[80%] max-w-md">
                          <img
                            src="/images/contact-popup.png"
                            alt=""
                            className="w-32"
                          />

                          <h2 className="text-base md:text-lg lg:text-xl max-md:leading-5 text-center font-bold my-2 mt-4 text-black">
                            The point person for all your investment-related
                            needs
                          </h2>
                          <div className="flex flex-col items-center text-base font-bold mt-2 gap-2">
                            <p className="rounded-full flex items-center bg-zinc-100">
                              {" "}
                              <span className="bg-[#5AB2FF] py-1.5 px-2 h-full text-lg md:text-xl lg:text-2xl rounded-full text-white">
                                <BiSolidPhoneCall />
                              </span>{" "}
                              <span className="py-1 px-3 text-base md:text-lg lg:text-xl">
                                (+91) -{" "}
                                {getAgentPhone(
                                  userKycData === null ||
                                    userKycData["agencyCode"] === null ||
                                    userKycData["agencyCode"] === undefined
                                    ? ""
                                    : userKycData["agencyCode"]
                                )}
                              </span>
                            </p>
                            <p className="rounded-full flex items-center bg-zinc-100">
                              <span className="bg-[#5AB2FF] py-1.5 px-2 h-full text-lg md:text-xl lg:text-2xl rounded-full text-white">
                                <IoIosMail />
                              </span>{" "}
                              <span className="py-1 px-3 text-base md:text-lg lg:text-xl">
                                {getAgentMail(
                                  userKycData === null ||
                                    userKycData["agencyCode"] === null ||
                                    userKycData["agencyCode"] === undefined
                                    ? ""
                                    : userKycData["agencyCode"]
                                )}
                              </span>
                            </p>
                          </div>

                          {/* Close button */}
                          <span
                            className="absolute top-3 right-3 hover:bg-zinc-200 p-1 rounded-full duration-200 cursor-pointer"
                            onClick={() => setContactPopUp(!contactPopUp)}
                          >
                            <span className="text-lg text-black">
                              <IoCloseCircle />
                            </span>
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                </>
              ) : page === "form16A" ? (
                <>
                  <div className="w-full lg:max-w-[700px] xl:max-w-[900px] flex flex-col py-2">
                    <Form16A />
                  </div>
                </>
              ) : page === "manageWALR" ? (
                <>
                  <div className="w-full lg:max-w-[700px] xl:max-w-[900px] flex flex-col py-1.5 pt-4 md:-mt-4">
                    <div className="flex flex-wrap justify-between items-start text-black border h-80 md:h-44 px-2.5 py-4 xl:pl-4 xl:pr-4 relative mt-3 rounded-lg">
                      <p className="absolute -top-4 left-3 md:left-4 border rounded-lg text-black bg-white px-2.5 py-1 font-semibold text-base w-[270px] md:w-[45%]">
                        <span className="font-semibold items-center flex">
                          <marquee>
                            <span className="text-[#0C2A75] font-bold">
                              0.5%
                            </span>{" "}
                            of Assets under Management per annum.
                          </marquee>
                        </span>
                      </p>
                      {/* Container-1 */}
                      <div className="relative w-full md:w-[320px] lg:w-[311px] xl:w-[350px] mt-1">
                        <div className="text-xs bg-[#3B71CA] text-white font-semibold rounded-t-xl ">
                          <p className="p-1.5 text-center">
                            Sum of WALR assets-under-management held presently
                            with the Company
                          </p>
                        </div>
                        <div className="p-3 font-semibold lg:text-base 2xl:text-lg rounded-lg flex justify-between items-center gap-1 bg-[#F0F8FD] cursor-pointer shadow-md hover:mt-[38px] duration-200 w-full  absolute top-0">
                          <div className="text-[#0C2A75]">
                            <h2>Relationship Value</h2>
                            <h2 className="font-bold mt-2 lg:text-lg xl:text-xl flex items-center gap-1">
                              <TbCoinRupeeFilled />{" "}
                              {walr?.toLocaleString("en-IN") || 0}
                            </h2>
                          </div>
                          <div className="pl-5 ">
                            <img
                              src="/images/wealth.png"
                              alt=""
                              className="w-[55px] xl:w-[60px]"
                            />
                          </div>
                        </div>
                      </div>
                      {/* Container-2 */}
                      <div className="relative w-full md:w-[320px] lg:w-[311px] xl:w-[350px] top-2 md:top-0 mt-1">
                        <div className="text-xs bg-[#3B71CA] text-white font-semibold rounded-t-xl">
                          <p className="p-1.5 text-center">
                            Total recurring fees deducted so far from you
                            against the DeAs managed
                          </p>
                        </div>
                        <div className="p-3 font-semibold lg:text-base 2xl:text-lg rounded-lg flex justify-between items-center gap-1 bg-[#F0F8FD] cursor-pointer shadow-md hover:mt-[38px] duration-200 w-full absolute top-0">
                          <div className="text-[#0C2A75]">
                            <h2>Total Management Fee</h2>
                            <h2 className="font-bold mt-2 lg:text-lg xl:text-xl flex items-center gap-1">
                              <TbCoinRupeeFilled />
                              {totalWALRFee.toLocaleString("en-IN")}
                            </h2>
                          </div>
                          <div className="pl-5 ">
                            <img
                              src="/images/bill.png"
                              alt=""
                              className="w-12 xl:w-14"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div>
                      <WalrHistory />
                    </div>
                  </div>
                </>
              ) : page === "manageRESIDEX" ? (
                <>
                  <div className="w-full lg:max-w-[700px] xl:max-w-[900px] flex flex-col py-1.5 pt-4 md:-mt-4">
                    <div className="flex flex-wrap justify-between items-start text-black border h-80 md:h-44 px-2.5 py-4 xl:pl-4 xl:pr-4 relative mt-3 rounded-lg">
                      <p className="absolute -top-4 left-3 md:left-4 border rounded-lg text-black bg-white px-2.5 py-1 font-semibold text-base w-[270px] md:w-[45%]">
                        <span className="font-semibold items-center flex">
                          <marquee>
                            <span className="text-[#0C2A75] font-bold ">
                              0.5%
                            </span>{" "}
                            of Assets under Management per annum.
                          </marquee>
                        </span>
                      </p>
                      {/* Container-1 */}
                      <div className="relative w-full md:w-[320px] lg:w-[311px] xl:w-[350px] mt-1">
                        <div className="text-xs bg-[#3B71CA] text-white font-semibold rounded-t-xl ">
                          <p className="p-1.5 text-center">
                            Sum of RESIDEX assets-under-management held
                            presently with the Company
                          </p>
                        </div>
                        <div className="p-3 font-semibold lg:text-base 2xl:text-lg rounded-lg flex justify-between items-center gap-1 bg-[#F0F8FD] cursor-pointer shadow-md hover:mt-[38px] duration-200 w-full  absolute top-0">
                          <div className="text-[#0C2A75]">
                            <h2>Relationship Value</h2>
                            <h2 className="font-bold mt-2 lg:text-lg xl:text-xl flex items-center gap-1">
                              <TbCoinRupeeFilled />
                              {residex?.toLocaleString("en-IN") || 0}
                            </h2>
                          </div>
                          <div className="pl-5 ">
                            <img
                              src="/images/wealth.png"
                              alt=""
                              className="w-[55px] xl:w-[60px]"
                            />
                          </div>
                        </div>
                      </div>

                      {/* Container-2 */}
                      <div className="relative w-full md:w-[320px] lg:w-[311px] xl:w-[350px] top-2 md:top-0 mt-1">
                        <div className="text-xs bg-[#3B71CA] text-white font-semibold rounded-t-xl">
                          <p className="p-1.5 text-center">
                            Total recurring fees deducted so far from you
                            against the DeAs managed
                          </p>
                        </div>
                        <div className="p-3 font-semibold lg:text-base 2xl:text-lg rounded-lg flex justify-between items-center gap-1 bg-[#F0F8FD] cursor-pointer shadow-md hover:mt-[38px] duration-200 w-full absolute top-0">
                          <div className="text-[#0C2A75]">
                            <h2>Total Management Fee</h2>
                            <h2 className="font-bold mt-2 lg:text-lg xl:text-xl flex items-center gap-1">
                              <TbCoinRupeeFilled />
                              {totalRESIDEXFee.toLocaleString("en-IN")}
                            </h2>
                          </div>
                          <div className="pl-5 ">
                            <img
                              src="/images/bill.png"
                              alt=""
                              className="w-12 xl:w-14"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div>
                      <ResidexHistory />
                    </div>
                  </div>
                </>
              ) : page === "Advisory" ? (
                <>
                  <div className="px-4 mx-auto w-full lg:max-w-[700px] xl:max-w-[900px] lg:pt-20 lg:px-6 flex flex-col items-center pt-4 md:-mt-4">
                    <div className="mb-4 max-w-lg flex justify-center">
                      <img src="/images/Error404img.jpg" alt="" />
                    </div>
                    <div className="mx-auto max-w-screen-sm text-center">
                      <h1 className="mb-3 text-xl tracking-tight font-extrabold lg:text-3xl text-[#00A4E2] ">
                        404 Not Found
                      </h1>
                      <p className="mb-4 text-3xl tracking-tight font-bold text-gray-900 md:text-4xl ">
                        Whoops! That page doesn’t exist.
                      </p>
                    </div>
                  </div>
                </>
              ) : (
                page === "Gascharge" && (
                  <>
                    <div className="px-4 mx-auto w-full lg:max-w-[700px] xl:max-w-[900px] lg:pt-20 lg:px-6 pt-4 md:-mt-4 flex flex-col items-center">
                      <div className="mb-4 max-w-lg flex justify-center">
                        <img src="/images/Error404img.jpg" alt="" />
                      </div>
                      <div className="mx-auto max-w-screen-sm text-center">
                        <h1 className="mb-3 text-xl tracking-tight font-extrabold lg:text-3xl text-[#00A4E2] ">
                          404 Not Found
                        </h1>
                        <p className="mb-4 text-3xl tracking-tight font-bold text-gray-900 md:text-4xl ">
                          Whoops! That page doesn’t exist.
                        </p>
                      </div>
                    </div>
                  </>
                )
              )}
            </div>

            {/* FAQ */}
            <div className="w-[90%] mx-auto">
              <FAQFee />
            </div>
          </div>
        </>
      )}
    </div>
  );
}
export default Pricing;
