import React, { useState, useEffect } from "react";
import "./admin.css"; // Ensure this path is correct
import secureLocalStorage from "react-secure-storage";
import { MdCloudUpload } from "react-icons/md";
import { FaArrowCircleRight } from "react-icons/fa";
import { RiDeleteBin6Fill } from "react-icons/ri";
function ManageDocument() {
  const [filename, setFilename] = useState();
  const [files, setFiles] = useState([]);
  const [filename2, setFilename2] = useState();
  const [filename3, setFilename3] = useState();
  const [filename4, setFilename4] = useState();
  const [filename5, setFilename5] = useState();
  const [filename6, setFilename6] = useState();
  const [filename7, setFilename7] = useState();
  const [disable, setDisable] = useState(false);
  const [found, setFound] = useState(true);
  const [verify, setverify] = useState(false);

  const storedData = secureLocalStorage.getItem("admin-calender");
  const admin_calendar = JSON.parse(storedData);

  useEffect(() => {
    if (admin_calendar?.length > 0) {
      const today = new Date();
      const formattedToday = today.toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });

      admin_calendar.forEach((event) => {
        if (event.National_holidays) {
          for (const [key, value] of Object.entries(event.National_holidays)) {
            if (key === formattedToday.replace(", ", " ")) {
              setDisable(true);
            }
          }
        }
      });
    }
  }, [admin_calendar]);


const checkExcel = () => {
    setFound(false);
    setDisable(true);
    fetch(`https://api-scdndestates.com/api/check-excel/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to find the file.");
        }
        return response.json();
      })
      .then((response) => {
        setDisable(true);
        if (response.message === "success") {
          alert("Process Executed Successfully.");
          setFound(true);
          setverify(true);
        } else {
          alert("File not Found! Please Upload the File...");
          setFound(false);
        }
      })
      .catch((error) => {
        // console.error("Error during file finding process:", error);
        setDisable(false);
      });
  };

  const Download_Excel = (excelname) => {
    fetch(`https://api-scdndestates.com/api/download-excel/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        excel: excelname,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to download the file");
        }
        return response.blob();
      })
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${excelname}.xlsx`); //or any other extension
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      })
      .catch((error) => {
        // console.error("Error during file download:", error);
      });
  };

  const Upload_Excel = (excelname, file) => {
    const formData = new FormData();
    formData.append("excel", excelname);
    formData.append("file", file);

    fetch(`https://api-scdndestates.com/api/upload-excel/`, {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to upload the file");
        }
        return response.json();
      })
      .then((response) => {
        alert(response.message);
        if (excelname === "UserPoolAccount") {
          setDisable(false);
        }
      })
      .catch((error) => {
        // console.error("Error during file upload:", error);
      });
  };

  const Delete_Excel = (excelname) => {
    fetch(`https://api-scdndestates.com/api/delete-excel/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        excel: excelname,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to delete the file");
        }
        return response.json();
      })
      .then((response) => {
        alert(response.message);
      })
      .catch((error) => {
        // console.error("Error during file delete:", error);
      });
  };

  return (
    <>

      {/* Pool A/c Addition */}
      <div className="border px-3 py-2 rounded-lg mt-2 bg-white relative text-black shadow-md">
        <h2 className="bg-white absolute -top-3 border rounded-md px-2 py-1 font-bold text-base">
          Pool A/C Addition
        </h2>

        <div className="my-4 flex items-center justify-between">
          <span className="font-semibold text-base xl:text-lg">E-Collect file</span>
          <div className="text-base xl:text-lg flex gap-5">
            <span className="rounded-lg">
              <input
                type="file"
                onChange={(e) => setFilename2(e.target.files[0])}
                className="flex h-10 w-[220px] xl:w-full rounded-md border border-input bg-white px-3 py-2 text-sm text-gray-400 file:border-0 file:bg-transparent file:text-gray-600 file:text-sm file:font-medium"
              />
            </span>
            <button
              className="button-excel w-[120px] animationIcon-shine-blue"
              onClick={() => Upload_Excel("UserPoolAccount", filename2)}
            >
              <i className="text-xl">
                <MdCloudUpload className="icon" />
              </i>
              <p className="font-semibold">Upload</p>

            </button>
          </div>
        </div>

        <div className="my-4 flex items-center justify-between">
          <span className="font-semibold text-base xl:text-lg">Client Bank Linking</span>
          <button
            className="w-[120px] start-button hover:scale-95 duration-300 py-2 rounded-lg font-semibold shadow-md"
            onClick={() => checkExcel()}
          >
            Start
          </button>
        </div>

        <div className="my-4 flex items-center justify-between">
          <span className="font-semibold text-base xl:text-lg">Rejected User A/C</span>
          <div className="text-base xl:text-lg flex gap-5">
            <button
              className="flex rounded-lg gap-2 justify-center items-center w-[120px] py-2 outline-none text-white font-semibold border-0 text-sm xl:text-base animationIcon-shine-blue hover:scale-95 duration-300"
              onClick={() => Download_Excel("UserPoolAccount")}
            >
              <span>Download</span>
              <span>
                <svg
                  id="fi_2874797"
                  enableBackground="new 0 0 512 512"
                  height="512"
                  viewBox="0 0 512 512"
                  width="512"
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-5 h-5"
                >
                  <path d="m432 192h-125.25c-27.99 0-50.75-22.76-50.75-50.75v-125.25c0-5.76 1.52-11.24 4.27-16h-161.52c-19.16 0-34.75 15.59-34.75 34.75v442.5c0 19.16 15.59 34.75 34.75 34.75h314.5c19.16 0 34.75-15.59 34.75-34.75v-289.52c-4.76 2.75-10.24 4.27-16 4.27zm-235.31 100.69c6.25-6.25 16.37-6.25 22.62 0l20.69 20.68v-97.37c0-8.84 7.16-16 16-16s16 7.16 16 16v97.37l20.69-20.68c6.24-6.25 16.38-6.25 22.62 0 6.25 6.24 6.25 16.38 0 22.62l-48 48c-3.373 3.46-8.231 5.022-12.85 4.62-8.012-1.07-5.485-.335-57.77-52.62-6.25-6.24-6.25-16.38 0-22.62zm139.31 139.31h-160c-8.84 0-16-7.16-16-16s7.16-16 16-16h160c8.84 0 16 7.16 16 16s-7.16 16-16 16zm-29.25-272h125.25l-144-144v125.25c0 10.34 8.41 18.75 18.75 18.75z"></path>
                </svg>
              </span>
            </button>
          </div>
        </div>

        <div className="my-4 flex items-center justify-between">
          <span className="font-semibold text-base xl:text-lg">Userpoolaccount.xlsx</span>
          <div className="text-base xl:text-lg flex gap-5">
            <button
              className="button-red rounded-lg w-[120px] py-2 outline-none text-white font-bold border-0 animationIcon-shine-red text-sm xl:text-base hover:scale-95 duration-300 flex items-center justify-between px-2.5 gap-2"
              onClick={() => Delete_Excel("UserPoolAccount")}
            >
              <span>Delete</span>
              <span className="text-white text-base xl:text-xl">
                <RiDeleteBin6Fill />
              </span>
            </button>
          </div>
        </div>
      </div>

      {/* Product & Statistics */}
      <div className="border px-4 py-2 rounded-lg bg-white relative text-black mt-5 shadow-md">
        <h2 className="bg-white absolute -top-3 border rounded-md px-3 py-1 font-bold text-base">
          Product & Statistics
        </h2>
        <div className="my-4 flex items-center justify-between">
          <span className="font-semibold text-base xl:text-lg">Fixed-Income & Real Estate Statistics</span>
          <div className="text-base xl:text-lg flex gap-5">
            <span className="rounded-lg">
              <input
                type="file"
                onChange={(e) => setFilename3(e.target.files[0])}
                className="flex h-10 w-[220px] xl:w-full rounded-md border border-input bg-white px-3 py-2 text-sm text-gray-400 file:border-0 file:bg-transparent file:text-gray-600 file:text-sm file:font-medium"
              />
            </span>
            <button
              className="button-excel w-[120px]"
              onClick={() => Upload_Excel("MonthlyWALRRates", filename3)}
            >
              <i className="text-xl">
                <MdCloudUpload className="icon" />
              </i>

              <p className="font-semibold text-center">Replace</p>
            </button>
          </div>
        </div>

        <div className="my-4 flex items-center justify-between">
          <span className="font-semibold text-base xl:text-lg">DeAs Products</span>
          <div className="text-base xl:text-lg flex gap-5">
            <span className="rounded-lg">
              <input
                type="file"
                onChange={(e) => setFilename4(e.target.files[0])}
                className="flex h-10 w-[220px] xl:w-full rounded-md border border-input bg-white px-3 py-2 text-sm text-gray-400 file:border-0 file:bg-transparent file:text-gray-600 file:text-sm file:font-medium"
              />
            </span>
            <button
              className="button-excel w-[120px]"
              onClick={() => Upload_Excel("ExcelC", filename4)}
            >
              <i className="text-xl">
                <MdCloudUpload className="icon" />
              </i>

              <p className="font-semibold">Replace</p>
            </button>
          </div>
        </div>
        <div className="my-4 flex items-center justify-between">
          <span className="font-semibold text-base xl:text-lg">Collateral Price-data</span>
          <div className="text-base xl:text-lg flex gap-5">
            <span className="rounded-lg">
              <input
                type="file"
                onChange={(e) => setFilename5(e.target.files[0])}
                className="flex h-10 w-[220px] xl:w-full rounded-md border border-input bg-white px-3 py-2 text-sm text-gray-400 file:border-0 file:bg-transparent file:text-gray-600 file:text-sm file:font-medium"
              />
            </span>
            <button
              className="button-excel w-[120px]"
              onClick={() => Upload_Excel("Custodian", filename5)}
            >
              <i className="text-xl">
                <MdCloudUpload className="icon" />
              </i>

              <p className="font-semibold">Replace</p>
            </button>
          </div>
        </div>
        <div className="my-4 flex items-center justify-between">
          <span className="font-semibold text-base xl:text-lg">Margin Assets Holding</span>
          <div className="text-base xl:text-lg flex gap-5">
            <span className="rounded-lg">
              <input
                type="file"
                onChange={(e) => setFilename6(e.target.files[0])}
                className="flex h-10 w-[220px] xl:w-full rounded-md border border-input bg-white px-3 py-2 text-sm text-gray-400 file:border-0 file:bg-transparent file:text-gray-600 file:text-sm file:font-medium"
              />
            </span>
            <button
              className="button-excel w-[120px]"
              onClick={() => Upload_Excel("Retail Debt List", filename6)}
            >
              <i className="text-xl">
                <MdCloudUpload className="icon" />
              </i>

              <p className="font-semibold">Replace</p>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default ManageDocument;

