import React, { useState } from "react";
import CheckAuth from "../../components/ServiceFunctions/CheckAuth";
import secureLocalStorage from "react-secure-storage";

function PopUp3({ onClose }) {
  const { email, phone } = CheckAuth();
  const [show, setShow] = useState(true);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isImageSaved, setIsImageSaved] = useState(false);

  const handleImageChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      if (file.size <= 1 * 1024 * 1024) {
        setSelectedImage(file);
        setIsImageSaved(true);
        const formData = new FormData();
        formData.append("form_name", "imageChange");
        formData.append("email", email);
        formData.append("phone", phone);
        formData.append("img", file);
        fetch("https://api-scdndestates.com/api/user-kyc-edit/", {
          method: "POST",
          body: formData,
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error("Failed to upload image");
            }
            return response.json();
          })
          .then((response) => {
            secureLocalStorage.setItem(
              "kycData",
              JSON.stringify(response.data)
            );
            window.location.reload();
            onClose();
          })
          .catch((error) => {
            // console.error("Error uploading image:", error);
          });
      } else {
        alert("Image size must be less than or equal to 1MB");
      }
    }
  };

  const handleRemoveImage = () => {
    const formData = new FormData();
    formData.append("form_name", "deleteIMG");
    formData.append("email", email);
    formData.append("phone", phone);
    fetch("https://api-scdndestates.com/api/user-kyc-edit/", {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to delete image");
        }
        return response.json();
      })
      .then((response) => {
        secureLocalStorage.setItem("kycData", JSON.stringify(response.data));
        window.location.reload();
      })
      .catch((error) => {
        // console.error("Error deleting image:", error);
      });
    setSelectedImage(null);
    setIsImageSaved(false);
    setShow(false);
    onClose();
  };

  return (
    <>
      {show && (
        <div className="demo1-container">
          <div className="pop2-content rounded-lg p-2 pop-up-animation max-md:scale-75">
            <div className="bg-[#5ab2ff] rounded-lg p-1 md:text-lg border text-light text-center  fw-bold">
              Edit Profile Picture
            </div>
            <div className=" pb-1  flex justify-content-center flex-column">
              <input
                id="image-profile"
                type="file"
                accept="image/*"
                onChange={handleImageChange}
                style={{ display: "none" }}
              />
              <label
                htmlFor="image-profile"
                className="text-[#212121] hover:bg-[#ddedfc] border-2 border-[#ddedfc] text-center rounded-lg font-bold py-2 cursor-pointer mt-1"
              >
                Upload Picture
              </label>

              <div
                className="text-[#212121] hover:bg-[#ddedfc] border-2 border-[#ddedfc] text-center rounded-lg font-bold py-2 cursor-pointer mt-1"
                onClick={handleRemoveImage}
              >
                Remove Picture
              </div>

              <div
                className="text-danger cursor-pointer-style text-center fw-bold fs-6 cursor-pointer mt-2 bg"
                onClick={() => {
                  setShow(false);
                  onClose();
                }}
              >
                Go Back
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default PopUp3;
