import "./ContactUs.css";
import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";

function ContactUs() {
  gsap.registerPlugin(ScrollTrigger);
  const stepRefs = useRef([]);

  const sectionRef = useRef(null);
  const sectionRefs = useRef([]);
  

  useEffect(() => {

    

    const element = sectionRef.current;
    gsap.fromTo(
      element,
      { "--line-width": "0%" },  // Initial state of the pseudo-element's width
      {
        "--line-width": "100%",   // Final state of the pseudo-element's width
        duration: 2,
        ease: "power2.out",
        scrollTrigger: {
          trigger: element,
          start: "top 100%",  // Adjust the start point as needed
          toggleActions: "play none none none",
        },
      })

      sectionRefs.current.forEach((section, index) => {
        gsap.fromTo(
          section,
          { scale: 1, opacity: 0 }, // Initial state: scaled down
          {
            scale: 1,
            opacity: 1,
            duration: 1,
            ease: "power2.out",
            scrollTrigger: {
              trigger: section,
              start: "top 100%", // Adjust when the animation should trigger
              toggleActions: "play none none none",
            },
            delay: index * 0.5, // Apply delay based on index for staggered effect
          }
        );
      });

    stepRefs.current.forEach((step, index) => {
      gsap.fromTo(
        step,
        { opacity: 0, x: -200 },
        {
          opacity: 1,
          x: 0,
          duration: 1,
          ease: "power2.out",
          scrollTrigger: {
            trigger: step,
            start: "top 80%",
            end: "top 50%",
            scrub: 1,
            markers: false,
            toggleActions: "play none none reverse",
          },
        }
      );
    });
    return () => {
      ScrollTrigger.getAll().forEach((trigger) => trigger.kill());
    };
  }, []);

  const addToRefs = (el) => {
    if (el && !sectionRefs.current.includes(el)) {
      sectionRefs.current.push(el);
    }
  };

  return (
    <div className="w-full flex justify-center ">
      <div className="h-max mt-10 lg:mt-14 bg-white w-[90%] md:w-[87%]">
        <div
          className="header-title flex flex-col  text-center   xl:text-left leading-tight  font-semibold tracking-wide text-gray-800 p-2   mb-1 "
          ref={(el) => (stepRefs.current[0] = el)}
        >
          <div className="font-bold  mt-1 mx-0 text-base  md:text-xl lg:text-2xl xl:text-3xl 2xl:text-text-[34px]">
            Remove all intermediaries in your
          </div>
          <div className="font-bold  mx-0 text-base  md:text-xl lg:text-2xl xl:text-3xl 2xl:text-text-[34px]">
            <span className="text-sky-400">wealth creation</span> in 4 easy steps
          </div>
        </div>

        <div
          className="w-full flex justify-center items-start mt-7 md:mt-14 gap-1 md:gap-3 px-2 md:px-0 relative group"
          ref={(el) => (stepRefs.current[1] = el)}
        >
          <div className="md:block max-md:hidden absolute  top-8  w-[75%] border-[1px] border-dashed border-gray-500 after:left-0 after:-bottom-0.5   after:w-0 after:h-1 after:bg-black after:duration-1000 after:ease-in after:group-hover:w-full after:absolute " ref={addToRefs} ></div>
          <div
        className="md:hidden max-md:block section-with-line absolute max-md:top-60 max-md:right-14 md:top-12 w-96 md:w-[75%] max-md:rotate-90"
        ref={sectionRef}
      ></div>
          <div className="relative flex md:flex-row max-md:flex-col max-md:gap-10 max-md:items-center md:items-start justify-center w-full">
            <a href="/register" className="w-56 md:w-[25%] justify-center ">
          <div className="w-full justify-center " ref={addToRefs} >
            <div className="flex max-md:flex-row md:flex-col justify-center hover:-translate-y-2 duration-300 w-max">
              <div className="flex items-center">
              <div className="max-w-20 w-full h-24">
                <img src="/images/password.png" className="w-20 bg-white p-2 " alt="" />
              </div>
              
            </div>
            <div className="md:ml-2 max-md:ml-5 max-md:mt-1">
              <p className="text-gray-700 uppercase text-sm md:text-sm  max-md:leading-5 md:leading-6 font-medium mt-1">
                Step 1
              </p>
              <p className="text-sky-500 text-base md:text-base lg:text-lg max-md:leading-5 md:leading-6 font-semibold">
              Register <br /> using OTP
              </p>
            </div></div>
          </div>
          </a>
          <a href="/UserKycForm" className="w-56 md:w-[25%] justify-center">
          <div className="w-full " ref={addToRefs}>
          <div className="flex max-md:flex-row md:flex-col justify-center hover:-translate-y-2 duration-300 w-max">
            <div className="flex items-center ">
              <div className="max-w-20 w-full h-24">
                <img src="/images/pan-verify.png" className="w-20 bg-white p-1 px-2" alt="" />
              </div>
              
            </div>
            <div className="md:ml-2 max-md:ml-5 max-md:mt-0">
              <p className="text-gray-700 uppercase text-sm md:text-sm  max-md:leading-5 md:leading-6 font-medium mt-1">
                Step 2
              </p>
              <p className="text-sky-500 text-base md:text-base lg:text-lg max-md:leading-5 md:leading-6 font-semibold ">
              PAN + <br /> Penny-drop
              </p>
            </div>
            </div>
          </div>
          </a>
          <a href=" " className="w-56 md:w-[25%] justify-center" onClick={(e) => {
    e.preventDefault(); // Prevent the default anchor behavior
    alert("The Distributed Ledger software will be used for all database records from Phase 5 onwards.");
  }}>
          <div className="w-full " ref={addToRefs}>
          <div className="flex max-md:flex-row md:flex-col justify-center hover:-translate-y-2 duration-300 w-max">
            <div className="flex items-center ">
              <div className="max-w-20 w-full h-24">
                <img src="/images/opensource-1.png" className="w-20 bg-white p-2" alt="" />
              </div>
              
            </div>
            <div className="md:ml-2 max-md:ml-5 max-md:mt-2">
              <p className="text-gray-700 uppercase text-sm md:text-sm  font-medium mt-1 max-md:leading-5 md:leading-6">
                Step 3
              </p>
              <p className="text-sky-500 text-base md:text-base lg:text-lg  font-semibold max-md:leading-5 md:leading-6 ">
              Install Opensource <br /> Blockchain
              </p>
            </div>
            </div>
          </div>
          </a>
          <div className="w-56 md:w-max justify-center bg-white relative md:-top-4" ref={addToRefs}>

          <div className="flex max-md:flex-row md:flex-col justify-center hover:-translate-y-2 duration-300 w-max">
            <div className="flex items-center ">
            <div className="max-w-20 w-full h-28 items-start flex ">
            <div className="text-start max-md:scale-100 md:scale-110 max-md:-translate-x-3">
                      <div className="verified-tick">
                        <svg
                          className="verified-star"
                          height="19"
                          viewBox="0 0 19 19"
                          width="19"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M8.296.747c.532-.972 1.393-.973 1.925 0l2.665 4.872 4.876 2.66c.974.532.975 1.393 0 1.926l-4.875 2.666-2.664 4.876c-.53.972-1.39.973-1.924 0l-2.664-4.876L.76 10.206c-.972-.532-.973-1.393 0-1.925l4.872-2.66L8.296.746z"></path>
                        </svg>
                        <svg
                          className="verified-star"
                          height="19"
                          viewBox="0 0 19 19"
                          width="19"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M8.296.747c.532-.972 1.393-.973 1.925 0l2.665 4.872 4.876 2.66c.974.532.975 1.393 0 1.926l-4.875 2.666-2.664 4.876c-.53.972-1.39.973-1.924 0l-2.664-4.876L.76 10.206c-.972-.532-.973-1.393 0-1.925l4.872-2.66L8.296.746z"></path>
                        </svg>
                        <svg
                          className="verified-star"
                          height="19"
                          viewBox="0 0 19 19"
                          width="19"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M8.296.747c.532-.972 1.393-.973 1.925 0l2.665 4.872 4.876 2.66c.974.532.975 1.393 0 1.926l-4.875 2.666-2.664 4.876c-.53.972-1.39.973-1.924 0l-2.664-4.876L.76 10.206c-.972-.532-.973-1.393 0-1.925l4.872-2.66L8.296.746z"></path>
                        </svg>
                        <svg
                          className="verified-star"
                          height="19"
                          viewBox="0 0 19 19"
                          width="19"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M8.296.747c.532-.972 1.393-.973 1.925 0l2.665 4.872 4.876 2.66c.974.532.975 1.393 0 1.926l-4.875 2.666-2.664 4.876c-.53.972-1.39.973-1.924 0l-2.664-4.876L.76 10.206c-.972-.532-.973-1.393 0-1.925l4.872-2.66L8.296.746z"></path>
                        </svg>
                        <svg
                          className="verified-star"
                          height="19"
                          viewBox="0 0 19 19"
                          width="19"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M8.296.747c.532-.972 1.393-.973 1.925 0l2.665 4.872 4.876 2.66c.974.532.975 1.393 0 1.926l-4.875 2.666-2.664 4.876c-.53.972-1.39.973-1.924 0l-2.664-4.876L.76 10.206c-.972-.532-.973-1.393 0-1.925l4.872-2.66L8.296.746z"></path>
                        </svg>
                        <svg
                          className="verified-star"
                          height="19"
                          viewBox="0 0 19 19"
                          width="19"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M8.296.747c.532-.972 1.393-.973 1.925 0l2.665 4.872 4.876 2.66c.974.532.975 1.393 0 1.926l-4.875 2.666-2.664 4.876c-.53.972-1.39.973-1.924 0l-2.664-4.876L.76 10.206c-.972-.532-.973-1.393 0-1.925l4.872-2.66L8.296.746z"></path>
                        </svg>
                        <svg
                          className="verified-tick__check"
                          height="16"
                          viewBox="0 0 48 36"
                          width="24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M47.248 3.9L43.906.667a2.428 2.428 0 0 0-3.344 0l-23.63 23.09-9.554-9.338a2.432 2.432 0 0 0-3.345 0L.692 17.654a2.236 2.236 0 0 0 .002 3.233l14.567 14.175c.926.894 2.42.894 3.342.01L47.248 7.128c.922-.89.922-2.34 0-3.23"></path>
                        </svg>
                        <svg
                          className="verified-tick__background"
                          height="60"
                          viewBox="0 0 120 115"
                          width="60"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M107.332 72.938c-1.798 5.557 4.564 15.334 1.21 19.96-3.387 4.674-14.646 1.605-19.298 5.003-4.61 3.368-5.163 15.074-10.695 16.878-5.344 1.743-12.628-7.35-18.545-7.35-5.922 0-13.206 9.088-18.543 7.345-5.538-1.804-6.09-13.515-10.696-16.877-4.657-3.398-15.91-.334-19.297-5.002-3.356-4.627 3.006-14.404 1.208-19.962C10.93 67.576 0 63.442 0 57.5c0-5.943 10.93-10.076 12.668-15.438 1.798-5.557-4.564-15.334-1.21-19.96 3.387-4.674 14.646-1.605 19.298-5.003C35.366 13.73 35.92 2.025 41.45.22c5.344-1.743 12.628 7.35 18.545 7.35 5.922 0 13.206-9.088 18.543-7.345 5.538 1.804 6.09 13.515 10.696 16.877 4.657 3.398 15.91.334 19.297 5.002 3.356 4.627-3.006 14.404-1.208 19.962C109.07 47.424 120 51.562 120 57.5c0 5.943-10.93 10.076-12.668 15.438z"></path>
                        </svg>
                      </div>
                    </div>
                    </div>
            </div>
            <div className="md:ml-2 max-md:ml-5 max-md:mt-3">
            <p className="text-gray-700 uppercase text-sm md:text-sm  font-medium mt-1 max-md:leading-5 md:leading-6">
                Step 4
              </p>
              <p className="text-sky-500 text-base md:text-base lg:text-lg  font-semibold  max-md:leading-5 md:leading-6">
                Ready to <br /> Trade DeAs
              </p>
            </div>
          </div>
          </div>
          </div>
        </div>
        
        

        <div
          className="w-full flex justify-center mt-10 "
          ref={(el) => (stepRefs.current[2] = el)}
        >
          <div className=" flex flex-wrap justify-center max-sm:py-3 max-sm:px-0 md:p-3 xl:p-5 max-sm:gap-5 md:gap-20 rounded-xl">
            <div class="home-container  shadow-[0px_0px_3px_1px_#00000024] hover:shadow-[0px_3px_3px_3px_#00000024] overflow-hidden cursor-pointer rounded-2xl">
              <div class="home-card  overflow-hidden">
                <div class="overflow-hidden img-content h-4/6">
                <div className="content-img  after:bg-[#c9f0ff] after:border after:border-[#4bc9ff33] border-2 border-[#7cdaff]">
                    <img
                      className="w-[80px] h-[80px] z-50 rotate-ani"
                      src="/images/empowerment.png"
                      alt=""
                    />
                  </div>
                </div>
                <div className="w-full h-2/6 flex flex-col items-center justify-start px-3 heading-content">
                <p className="text-sky-400 group-hover:text-white text-lg lg:text-2xl font-bold  text-center mb-1  ">
                    Empowerment
                  </p>
                  <p className="text-[#163b4a] group-hover:text-sky-50 text-sm font-semibold text-center">
                    We empower you to take direct control of the value of your
                    assets ...
                  </p>
                </div>

                <div class="content-home bg-[#ffffffc5] ">
                  <p class="text-2xl font-bold text-sky-400">
                    Empowerment
                  </p>
                  <p className="font-semibold text-sm text-[#163b4a]">
                    We empower you to take direct control of the value of your
                    assets. Whether you are saving for a home or retirement (and
                    everything in between), we provide the tools and
                    infrastructure to remove all unknowns and contingencies in
                    your path.
                  </p>
                </div>
              </div>
            </div>

            <div class="home-container shadow-[0px_0px_3px_1px_#00000024] hover:shadow-[0px_3px_3px_3px_#00000024]  duration-300 ease-in-out overflow-hidden cursor-pointer rounded-2xl">
              <div class="home-card  overflow-hidden">
                <div class="   overflow-hidden img-content h-4/6">
                <div className="content-img  after:bg-[#c9f0ff] after:border after:border-[#4bc9ff33] border-2 border-[#7cdaff]">
                    <img
                      className="w-[80px] h-[80px] z-50 rotate-ani"
                      src="/images/scale.png"
                      alt=""
                    />
                  </div>
                </div>
                <div className="w-full h-2/6 flex flex-col items-center justify-start px-3 heading-content">
                <p className="text-sky-400 group-hover:text-white text-lg lg:text-2xl font-bold  text-center mb-1  ">
                    Fairness
                  </p>
                  <p className="text-[#163b4a] group-hover:text-sky-50 text-sm font-semibold text-center">
                    We believe that everyone deserves a fair shot at financial
                    success ...
                  </p>
                </div>

                <div class="content-home bg-[#ffffffc5] ">
                  <p class="text-2xl font-bold text-sky-400">
                    Fairness
                  </p>
                  <p className="font-semibold text-sm text-[#163b4a]">
                    We believe that everyone deserves a fair shot at financial
                    success. Our platform and services are designed to level the
                    playing field, offering you the same opportunities that were
                    once reserved for the financial elite.
                  </p>
                </div>
              </div>
            </div>

            <div class="home-container shadow-[0px_0px_3px_1px_#00000024] hover:shadow-[0px_3px_3px_3px_#00000024] overflow-hidden cursor-pointer rounded-2xl">
              <div class="home-card  overflow-hidden">
                <div class="   overflow-hidden img-content h-4/6">
                  <div className="content-img  after:bg-[#c9f0ff] after:border after:border-[#4bc9ff33] border-2 border-[#7cdaff]">
                    <img
                      className="w-[80px] h-[80px] z-50 rotate-ani"
                      src="/images/lightbulb.png"
                      alt=""
                    />
                  </div>
                </div>
                <div className="w-full h-2/6 flex flex-col items-center justify-start px-3 heading-content">
                  <p className="text-sky-400 group-hover:text-white text-lg lg:text-2xl font-bold  text-center mb-1  ">
                    Innovation
                  </p>
                  <p className="text-[#163b4a] group-hover:text-sky-50 text-sm font-semibold text-center">
                    We leverage cutting-edge AI and Distributed Ledger
                    technology to ...
                  </p>
                </div>

                <div class="content-home bg-[#ffffffc5] ">
                  <p class="text-2xl font-bold text-sky-400">Innovation</p>
                  <p className="font-semibold text-sm text-[#163b4a]">
                    We leverage cutting-edge AI and Distributed Ledger
                    technology to create innovative financial solutions that is
                    aimed at removing all centralized price-setters and
                    record-keepers, whose own interests always take precedence
                    over your money.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-2 md:mt-10 flex  items-center justify-center ">
          <a
            class=" text-sm lg:text-base group items-center inline-flex w-max text-center  font-semibold text-sky-400 hover:text-sky-500  duration-300 bg-white/5  rounded-3xl hover:-translate-y-1 "
            href="/UserKycForm"
          >
            Start your KYC
            <div class="arrow-move ml-1">
              <svg
                className=" fill-sky-400 group-hover:fill-sky-500"
                xmlns="http://www.w3.org/2000/svg"
                id="Outline"
                viewBox="0 0 24 24"
                width="30"
                height="30"
              >
                <path d="M18,12h0a2,2,0,0,0-.59-1.4l-4.29-4.3a1,1,0,0,0-1.41,0,1,1,0,0,0,0,1.42L15,11H5a1,1,0,0,0,0,2H15l-3.29,3.29a1,1,0,0,0,1.41,1.42l4.29-4.3A2,2,0,0,0,18,12Z" />
              </svg>
            </div>
          </a>
        </div>
      </div>
    </div>
  );
}

export default ContactUs;
