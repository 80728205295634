import React, { useState, useEffect } from "react";
import "./UserKYCForm.css";
import UserDetails from "../../components/KYCPage/UserDetails";
import PopUp1 from "../../components/PopUp1/PopUp1";
import TaxDetails from "../../components/KYCPage/TaxDetails";
import Nominee from "../../components/KYCPage/Nominee";
import ClientVerification from "../../components/KYCPage/ClientVerification";
import PoolAccount from "../../components/KYCPage/PoolAccount";
import SignupMode from "../../components/KYCPage/SignupMode.jsx";
// import BlockchainKey from "../../components/KYCPage/BlockchainKey.jsx";
import { useLocation } from "react-router-dom";
import { MdCheckBox } from "react-icons/md";
import { RiEdit2Fill } from "react-icons/ri";
import Header from "../../components/Header/Header.jsx";
import { IoIosTimer } from "react-icons/io";
import { ImCross } from "react-icons/im";
import CheckAuth from "../../components/ServiceFunctions/CheckAuth";
import UserData from "../../components/ServiceFunctions/UserData";
import secureLocalStorage from "react-secure-storage";
import DrawerComponent from "./DrawerKYCForm.jsx";

function UserKYCForm() {
  const { isAuthenticated, email, phone, pan } = CheckAuth();
  const {
    statusA,
    statusB,
    statusC,
    statusD,
    statusE,
    statusF,
    statusG,
    statusH,
    statusI,
  } = UserData({
    isAuthenticated,
    email,
    pan,
    phone,
    formName: "",
  });
  const location = useLocation();

  const storedData = secureLocalStorage.getItem("kycData");
  const userKycData = JSON.parse(storedData);

  const total = Object.values(userKycData?.["KYC_status"] || {}).reduce(
    (acc, val) => acc + (val || 0),
    0
  );

  useEffect(() => {
    if (location.state && location.state.selectedCard) {
      const selected = KYCFormApi.find(
        (card) => card.name === location.state.selectedCard
      );
      if (selected) {
        setSelectedCard(selected);
      }
    }
  }, [location.state]);

  const KYCFormApi = [
    {
      Id: 1,
      title: "Personal Info",
      name: "User Details",
      content: (
        <>
          <UserDetails />
        </>
      ),
    },
    {
      Id: 2,
      title: "Personal Info",
      name: "Tax Status",
      content: (
        <>
          <TaxDetails />
        </>
      ),
    },
    {
      Id: 3,
      title: "Bank Account",
      name: "Client Verification",
      content: (
        <>
          <ClientVerification />
        </>
      ),
    },
    {
      Id: 4,
      title: "Bank Account",
      name: "Pool A/C Addition",
      content: (
        <>
          <PoolAccount />
        </>
      ),
    },
    {
      Id: 5,
      title: "Registration",
      name: "Nominee",
      content: (
        <>
          <Nominee />
        </>
      ),
    },
    // {
    //   Id: 6,
    //   title: "Registration",
    //   name: "Blockchain Key",
    //   content: (
    //     <>
    //       <BlockchainKey />
    //     </>
    //   ),
    // },
    {
      Id: 6,
      title: "Registration",
      name: "Signup Mode",
      content: (
        <>
          <SignupMode />
        </>
      ),
    },
    // {
    //   Id: 7,
    //   title: "Terms & Conditions",
    //   name: "DSC",
    //   content: (
    //     <>
    //       <DSCPapers />
    //     </>
    //   ),
    // },
  ];

  const [selectedCard, setSelectedCard] = useState(KYCFormApi[0]);

  const handleSelectCard = (card) => {
    const cardIndex = KYCFormApi.findIndex((c) => c.name === card.name);
    const previousCards = KYCFormApi.slice(0, cardIndex);
    const canNavigate = previousCards.every((c) => {
      switch (card.name) {
        case "User Details":
          if (statusA === 0) return false;
          else return true;
        case "Tax Status":
          if (statusB === 0) return false;
          else return true;
        case "Client Verification":
          if (statusC === 0) return false;
          else return true;
        case "Pool A/C Addition":
          if (statusD === 0) return false;
          else return true;
        case "Nominee":
          if (statusE === 0 && statusF === 0) return false;
          else return true;
        case "Signup Mode":
          if (statusF === 0) return false;
          else return true;
        case "DSC":
          if (statusG === 0) return false;
          else return true;
        default:
          return false;
      }
    });
    if (canNavigate) {
      setSelectedCard(card);
    } else {
      alert("Please complete the previous steps before proceeding.");
    }
  };

  const groupedButtons = KYCFormApi.reduce((acc, card) => {
    if (!acc[card.title]) {
      acc[card.title] = [];
    }
    acc[card.title].push(card);
    return acc;
  }, {});

  return (
    <>
      {/* <Header /> */}
      {!isAuthenticated ? (
        <PopUp1 />
      ) : (
        <>
          <div className="toplogo mt-4">
            <a href="/">
              <img
                className="mx-auto h-12 w-auto"
                src="/images/logo01.png"
                alt="Your Company"
              />
            </a>
          </div>

          <div className="container-fluid  container-user-kyc m-0 px-0 ">
            <div className="flex justify-center p-6 px-2 xl:mx-16">
              <div className="w-1/12 lg:w-4/12 2xl:w-3/12  px-2 2xl:px-4 py-3">
                <div className="drawer block lg:hidden ">
                  <DrawerComponent
                    selectedCard={selectedCard}
                    handleSelectCard={handleSelectCard}
                  />
                </div>
                <div className="block max-lg:hidden border p-2 xl:p-4 m-0 shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)] rounded-xl">
                  <h6 className="px-3 d-flex justify-content-between">
                    <span className="text-lg font-bold leading-9 tracking-normal   text-[#212121]">
                      KYC PROCESS
                    </span>
                    <span
                      className="text-sm font-bold leading-9 tracking-normal   text-[#212121]"
                      style={{ color: "#5ab2ff" }}
                    >
                      {total}% Completed
                    </span>
                  </h6>
                  <hr className="my-2 p-0" />
                  {Object.entries(groupedButtons).map(
                    ([title, cards], index) => (
                      <div className="px-3" key={title}>
                        <div key={index} className="kyc-btn-heading py-2">
                          {title}
                        </div>
                        {cards.map((card) => (
                          <div
                            key={card.Id}
                            className={`${
                              selectedCard.Id === card.Id
                                ? "selected3  mb-1 rounded-xl bg-[#5ab2ff]"
                                : "kyc-btn-style mb-1 rounded-xl bg-[#f0f0f0]"
                            }`}
                            onClick={() => handleSelectCard(card)}
                          >
                            <div className="flex justify-between ">
                              <div>{card.name}</div>
                              <div className="flex items-center">
                                {(() => {
                                  switch (card.name) {
                                    case "User Details":
                                      return userKycData?.["KYC_status"]?.[
                                        "B"
                                      ] === 15 ? (
                                        <MdCheckBox size={20} color="green" />
                                      ) : userKycData?.["KYC_status"]?.["B"] ===
                                          5 ||
                                        userKycData?.["KYC_status"]?.["B"] ===
                                          10 ? (
                                        <RiEdit2Fill
                                          size={20}
                                          color="#0051d9"
                                        />
                                      ) : (
                                        <></>
                                      );
                                    case "Tax Status":
                                      return userKycData?.["KYC_status"]?.[
                                        "C"
                                      ] === 20 ? (
                                        <MdCheckBox size={20} color="green" />
                                      ) : userKycData?.["KYC_status"]?.["C"] ===
                                          15 ||
                                        userKycData?.["KYC_status"]?.["C"] ===
                                          10 ||
                                        userKycData?.["KYC_status"]?.["C"] ===
                                          5 ? (
                                        <RiEdit2Fill
                                          size={20}
                                          color="#0051d9"
                                        />
                                      ) : (
                                        <></>
                                      );
                                    case "Client Verification":
                                      return userKycData?.["KYC_status"]?.[
                                        "D"
                                      ] === 10 ? (
                                        <MdCheckBox size={20} color="green" />
                                      ) : userKycData?.["KYC_status"]?.["D"] ===
                                        5 ? (
                                        <RiEdit2Fill
                                          size={20}
                                          color="#0051d9"
                                        />
                                      ) : (
                                        <></>
                                      );
                                    case "Pool A/C Addition":
                                      return userKycData?.["KYC_status"]?.[
                                        "E"
                                      ] === 15 ? (
                                        <MdCheckBox size={20} color="green" />
                                      ) : userKycData?.["KYC_status"]?.["E"] ===
                                        5 ? (
                                        <ImCross size={15} color="red" />
                                      ) : userKycData?.["KYC_status"]?.["E"] ===
                                        10 ? (
                                        <IoIosTimer size={20} color="orange" />
                                      ) : (
                                        <></>
                                      );
                                    case "Nominee":
                                      return userKycData?.["KYC_status"]?.[
                                        "F"
                                      ] === 10 ? (
                                        <MdCheckBox size={20} color="green" />
                                      ) : userKycData?.["KYC_status"]?.["F"] ===
                                        5 ? (
                                        <RiEdit2Fill
                                          size={20}
                                          color="#0051d9"
                                        />
                                      ) : (
                                        <></>
                                      );
                                    case "Signup Mode":
                                      return userKycData?.["KYC_status"]?.[
                                        "G"
                                      ] === 10 ? (
                                        <MdCheckBox size={20} color="green" />
                                      ) : userKycData?.["KYC_status"]?.["G"] ===
                                        5 ? (
                                        <RiEdit2Fill
                                          size={20}
                                          color="#0051d9"
                                        />
                                      ) : (
                                        <></>
                                      );
                                    default:
                                      return null;
                                  }
                                })()}
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    )
                  )}
                </div>
              </div>
              <div className="w-11/12 lg:w-8/12 2xl:w-9/12 flex justify-center px-2 2xl:px-4 py-3">
                <div className="border-none md:border pl-1 md:p-4 m-0 w-full md:shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)]  rounded-xl ">
                  {selectedCard && (
                    <>
                      <h6 className="text-2xl font-bold leading-9 tracking-tight text-[#212121] mb-2">
                        {selectedCard.name}
                      </h6>
                      <h6 className="text-md font-medium leading-5 tracking-tight text-[#464646]">
                        Please provide the following details which are required
                        for KYC Process.
                      </h6>
                      <hr className="m-0 p-0 my-2" />
                      <div c>{selectedCard.content}</div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default UserKYCForm;
