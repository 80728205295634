import React, { useState, useEffect, useRef } from "react";
import "./MarketAnimation.css";
import gsap from "gsap";
import IndiaMap from './indiamap.jsx';
import { ScrollTrigger } from "gsap/ScrollTrigger";

const DraggableCircularProgressBar = () => {
  const progressCircleRef = useRef(null);
  const progressRingContainerRef = useRef(null);
  const [progress, setProgress] = useState(1);
  const [marketValue, setMarketValue] = useState(35470100000);
  const [isDragging, setIsDragging] = useState(false);
  const [isHoveredHigh, setIsHoveredHigh] = useState(false); // State for hover
  const [isHoveredRing, setIsHoveredRing] = useState(false); // State for hover

  const radius = 65;
  const circumference = 2 * Math.PI * radius;

  useEffect(() => {
    const progressCircle = progressCircleRef.current;
    progressCircle.style.strokeDasharray = `${circumference}`;
    progressCircle.style.strokeDashoffset = `${circumference}`;

    const updateProgress = (percent) => {
      const offset = circumference - (percent / 100) * circumference;
      progressCircle.style.strokeDashoffset = offset;
    };

    updateProgress(progress);
  }, [progress, circumference]);

  const getProgressFromMouse = (e) => {
    const rect = progressRingContainerRef.current.getBoundingClientRect();
    const centerX = rect.left + rect.width / 2;
    const centerY = rect.top + rect.height / 2;
    const mouseX = e.clientX - centerX;
    const mouseY = e.clientY - centerY;
    const angle = Math.atan2(mouseY, mouseX) * (180 / Math.PI) + 90;
    const progressAngle = angle < 0 ? 360 + angle : angle;
    return (progressAngle / 360) * 100;
  };

  const handleMouseDown = (e) => {
    setIsDragging(true);
    const newProgress = getProgressFromMouse(e);
    setProgress(newProgress);
  };

  const handleMouseMove = (e) => {
    if (isDragging) {
      const newProgress = getProgressFromMouse(e);
      setProgress(newProgress);
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleInputChange = (e) => {
    let newProgress = parseInt(e.target.value);
    if (newProgress >= 0 && newProgress <= 100) {
      setProgress(newProgress); // Update progress based on input
    } else if (newProgress < 0) {
      setProgress(0); // Handle negative values
    } else if (newProgress > 100) {
      setProgress(100); // Cap at 100
    }
  };

  const incrementProgress = () => {
    setProgress((prev) => Math.min(prev + 1, 100)); // Increment progress
  };

  const decrementProgress = () => {
    setProgress((prev) => Math.max(prev - 1, 0)); // Decrement progress
  };

  useEffect(() => {
    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };
  });


  const decentralisedRef = useRef(null);
  const waveRef1 = useRef(null);
  const waveRef2 = useRef(null);
  const waveRef3 = useRef(null);
  const servedMarketRef = useRef(null);
  const stepRefs = useRef([]);



  useEffect(() => {
    [waveRef1, waveRef2, waveRef3].forEach((waveRef, index) => {
      gsap.fromTo(
        waveRef.current,
        { scale: 0, opacity: 1 },
        {
          scale: 4,
          opacity: 0,
          duration: 5,
          repeat: -1,
          delay: index * 1.5,
          ease: "power1.out",
        }
      );
    });

    let obj = { val: 0 };

    gsap.to(obj, {
      val: 25,
      duration: 2,
      ease: "none",
      scrollTrigger: {
        trigger: servedMarketRef.current,
        start: "top 80%",
        toggleActions: "play none none none",
      },
      onUpdate: function () {
        if (servedMarketRef.current) {
          setPercentage(Math.round(obj.val)); // Update state with the rounded value
        }
      },
      onComplete: () => {
        if (servedMarketRef.current) {
          setPercentage(25); // Set to 25% on complete
        }
      },
    });
  }, []);

  const [percentage, setPercentage] = useState(0); // Track the percentage in state

  const calculateMarketValue = () => {
    const newMarketValue = (35470100000 / 25) * percentage;
    return Math.round(newMarketValue);
  };

  const incrementPercentage = () => {
    setPercentage((prev) => {
      const newVal = Math.min(prev + 1, 100); // Increment and limit the value to 100%
      if (servedMarketRef.current) {
        servedMarketRef.current.textContent = `${newVal}%`;
      }
      return newVal;
    });
  };

  const resetValues = () => {
    setPercentage(25); // Reset percentage to 25
    setMarketValue(35470100000); // Reset market value to initial value
    if (servedMarketRef.current) {
      servedMarketRef.current.textContent = "25%"; // Update the displayed percentage
    }
  };

  useEffect(() => {
    // Whenever the percentage changes, update the market value
    setMarketValue(calculateMarketValue());
  }, [percentage]);

  return (
    <div className="relative mt-3 flex flex-col items-center max-xl:scale-75 max-md:scale-[112%]  max-md:-top-12 ">
            {/* Map of India */}
            {/* <img
              src="/images/india.svg"
              alt="India Map"
              className=" w-auto opacity-100"
              style={{
                filter: "drop-shadow(2px 2px 0px rgba(134, 134, 134, 1))",
              }}
            /> */}
                 <div class=" px-4 mx-auto relative top-3 transform scale-[60%] max-md:block md:hidden">
        <div class="mx-auto p-3 pb-2 shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_2px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_2px_rgba(25,28,33,0.08)] bg-white rounded-lg shadow-dashboard w-[420px]">
          <div class="flex flex-wrap items-center justify-between mb-1 -m-2">
            <div class="w-auto p-1">
              <h2 class="text-lg font-bold text-gray-900">Market Coverage</h2>
            </div>
            {/* {percentage >= 25 && (
        <button
        onClick={resetValues}
        className="bg-red-400 px-2 py-1 mr-2 text-sm text-white rounded"
      >
        Reset
      </button>
        
      )} */}
          </div>
          <div class="flex flex-wrap">
            <div class="w-full border-b border-coolGray-100 pb-1">
              <div class="flex flex-wrap items-center justify-between py-2 -m-2">
                <div class="w-auto p-1">
                  <div class="flex flex-wrap items-center -m-2">
                    <div class="w-auto p-1">
                      <div class="flex items-center justify-center w-14 h-10 bg-green-100 rounded-md">
                        <h3 class="text-base font-bold text-coolGray-400 text-center">
                          {Math.round(progress)}%
                        </h3>
                      </div>
                    </div>
                    <div class="w-auto p-1">
                      <h2 class="text-base font-semibold text-gray-800 leading-4">
                        Decentralized <br /> Assets
                      </h2>
                    </div>
                  </div>
                </div>
                <div class="w-auto p-1 text-end">
                  <p class="text-xs font-medium text-coolGray-400">AUM</p>
                  <p class="text-xl text-coolGray-500 font-bold">
                    {new Intl.NumberFormat("en-IN", {
                      style: "currency",
                      currency: "INR",
                    }).format((marketValue * Math.round(progress)) / 10000000) +
                      " Cr"}
                  </p>
                </div>
              </div>
            </div>
            <div class="w-full pt-1">
              <div class="flex flex-wrap items-center justify-between py-2 -m-2">
                <div class="w-auto p-1">
                  <div class="flex flex-wrap items-center -m-2">
                    <div class="w-auto p-1">
                      <div class="flex items-center justify-center w-14 h-10 bg-gray-100 rounded-md">
                        <h3 class="text-base font-bold text-coolGray-400 text-center">
                          {100 - Math.round(progress)}%
                        </h3>
                      </div>
                    </div>
                    <div class="w-auto p-1">
                      <h2 class="text-base font-semibold text-gray-800 leading-4">
                      Stock ETFs, <br /> Debt MFs, REITs
                      </h2>
                    </div>
                  </div>
                </div>
                <div class="w-auto p-1 text-end">
                  <p class="text-xs font-medium text-coolGray-400">AUM</p>
                  <p class="text-xl text-coolGray-500 font-bold">
                    {new Intl.NumberFormat("en-IN", {
                      style: "currency",
                      currency: "INR",
                    }).format(
                      Math.floor(
                        (marketValue * (100 - Math.round(progress))) / 10000000
                      )
                    ) + " Cr"}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
<div className="max-md:h-96 flex items-center">
< IndiaMap percentage={percentage} /></div>


            {/* Served market percentage */}

            <div
              className="absolute "
              style={{
                top: "50%",
                left: "40%",
                left: "40%",
                transform: "translate(-50%, -50%)",
              }}
            >
     
              <div className="absolute max-md:-top-8 md:-top-10 max-md:-right-[520px] md:-right-[480px]">
                <div className="chart-container max-md:scale-75 md:scale-125">
                <div className="containerRing">
      <div className="absolute  max-md:top-[52px] md:top-[47px] z-50">
        <div className="font-bold text-xs text-center text-sky-800">
          Served Market{" "}
        </div>
        <div className="text-center flex items-center justify-center">
      <div
        className="served-market font-bold text-xl md:text-3xl text-center text-sky-800"
        ref={servedMarketRef}
      >
        {percentage}% 
      </div>

      {/* Show button only after percentage reaches 25 */}
      <div className="flex flex-col ">
      {percentage >= 25 && (
<button
  title="Add New"
  class="group cursor-pointer outline-none hover:rotate-90 duration-300"
  onClick={incrementPercentage}
>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25px"
    height="25px"
    viewBox="0 0 24 24"
    class="stroke-sky-400 fill-none group-hover:fill-sky-800 group-active:stroke-sky-200 group-active:fill-sky-600 group-active:duration-0 duration-300"
  >
    <path
      d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"
      stroke-width="1.5"
    ></path>
    <path d="M8 12H16" stroke-width="1.5"></path>
    <path d="M12 16V8" stroke-width="1.5"></path>
  </svg>
</button>

        
      )}
      </div>
    </div>

        <div className="font-bold text-[8px] text-center text-sky-800">
          (As of now)
        </div>
      </div>
      <div className="wave-container border-8 border-gray-500   ">
        <div className="wave wave1 absolute z-30"></div>
        <div className="wave wave2 absolute z-30"></div>
        <div className="wave wave3 absolute z-30"></div>
      </div>

      <div
        className="progress-ring-container cursor-pointer absolute -top-[150px] z-40"
        id="progress-ring-container"
        ref={progressRingContainerRef}
        onMouseDown={handleMouseDown}
      >
        <svg className="progress-ring " width="150" height="150">
          <circle
            className="progress-ring__background "
            stroke="#ddd"
            strokeLinecap="round"
            strokeWidth="20"
            fill="transparent"
            r={radius}
            cx="75"
            cy="75"
            onMouseEnter={() => setIsHoveredRing(true)} // Set hover state
            onMouseLeave={() => setIsHoveredRing(false)} // Reset hover state
          />
          <circle
            ref={progressCircleRef}
            className="progress-ring__circle "
            stroke="#4caf50"
            strokeWidth="20"
            strokeLinecap="round"
            fill="transparent"
            r={radius}
            cx="75"
            cy="75"
            onMouseEnter={() => setIsHoveredHigh(true)} // Set hover state
            onMouseLeave={() => setIsHoveredHigh(false)} // Reset hover state
          />
        </svg>
      </div>

      {/* Editable value container */}
      <div className="absolute flex flex-col items-center scale-90 z-50">
        {/* <div className="absolute -top-12 text-xs font-semibold bg-gray-700 text-sky-300 rounded-lg w-max px-2 py-1 ">
          <p>Centralised Assets</p>
        </div> */}
        <div className="w-2 h-2 bg-white absolute -top-1 rotate-45"></div>
        <div className="flex absolute -top-6 ">
          <button
            onClick={decrementProgress}
            className="bg-slate-700 text-white px-2 rounded-l-lg"
          >
            -
          </button>
          <input
            type="number"
            value={Math.round(progress)}
            onChange={handleInputChange}
            className="bg-gray-50 w-8 outline-none border-0 text-sm font-semibold text-end"
          />
          <span className="bg-gray-50 text-start border-0 outline-none pr-2.5  font-semibold ">
            %
          </span>
          <button
            onClick={incrementProgress}
            className="bg-slate-700  text-white px-2 rounded-r-lg"
          >
            +
          </button>
        </div>
      </div>
      {/* {isHoveredHigh && (
        <div className="absolute -top-12 text-xs transition duration-200 ease-in-out font-semibold bg-gray-700 text-sky-100 rounded-lg w-max px-2 py-1 animate-fadeIn">
          <p>Centralised Assets</p>
        </div>
      )} */}
      {/* <div className="absolute -top-12 text-xs transition duration-200 ease-in-out font-semibold bg-gray-700 text-sky-100 rounded-lg w-max px-2 py-1 animate-fadeIn  z-50">
        <p>Decentralised Assets</p>
      </div> */}
      {/* {isHoveredRing && (
        <div className="absolute top-40 text-xs transition duration-200 ease-in-out font-semibold bg-gray-700 text-sky-100 rounded-lg w-max px-2 py-1 animate-fadeIn">
          <p>Decentralised Assets</p>
        </div>
      )} */}
      <div class=" px-4 mx-auto transform scale-[0.7]  absolute -top-56 md:left-[470px] max-md:hidden md:block">
        <div class="mx-auto p-3 pb-2 shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_2px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_2px_rgba(25,28,33,0.08)] bg-white rounded-lg shadow-dashboard w-[420px]">
          <div class="flex flex-wrap items-center justify-between mb-1 -m-2">
            <div class="w-auto p-1">
              <h2 class="text-lg font-bold text-gray-900">Market Coverage</h2>
            </div>
            {/* {percentage >= 25 && (
        <button
        onClick={resetValues}
        className="bg-red-400 px-2 py-1 mr-2 text-sm text-white rounded"
      >
        Reset
      </button>
        
      )} */}
          </div>
          <div class="flex flex-wrap">
            <div class="w-full border-b border-coolGray-100 pb-1">
              <div class="flex flex-wrap items-center justify-between py-2 -m-2">
                <div class="w-auto p-1">
                  <div class="flex flex-wrap items-center -m-2">
                    <div class="w-auto p-1">
                      <div class="flex items-center justify-center w-14 h-10 bg-green-100 rounded-md">
                        <h3 class="text-base font-bold text-coolGray-400 text-center">
                          {Math.round(progress)}%
                        </h3>
                      </div>
                    </div>
                    <div class="w-auto p-1">
                      <h2 class="text-base font-semibold text-gray-800 leading-4">
                        Decentralized <br /> Assets
                      </h2>
                    </div>
                  </div>
                </div>
                <div class="w-auto p-1 text-end">
                  <p class="text-xs font-medium text-coolGray-400">AUM</p>
                  <p class="text-xl text-coolGray-500 font-bold">
                    {new Intl.NumberFormat("en-IN", {
                      style: "currency",
                      currency: "INR",
                    }).format((marketValue * Math.round(progress)) / 10000000) +
                      " Cr"}
                  </p>
                </div>
              </div>
            </div>
            <div class="w-full pt-1">
              <div class="flex flex-wrap items-center justify-between py-2 -m-2">
                <div class="w-auto p-1">
                  <div class="flex flex-wrap items-center -m-2">
                    <div class="w-auto p-1">
                      <div class="flex items-center justify-center w-14 h-10 bg-gray-100 rounded-md">
                        <h3 class="text-base font-bold text-coolGray-400 text-center">
                          {100 - Math.round(progress)}%
                        </h3>
                      </div>
                    </div>
                    <div class="w-auto p-1">
                      <h2 class="text-base font-semibold text-gray-800 leading-4">
                      Stock ETFs, <br /> Debt MFs, REITs
                      </h2>
                    </div>
                  </div>
                </div>
                <div class="w-auto p-1 text-end">
                  <p class="text-xs font-medium text-coolGray-400">AUM</p>
                  <p class="text-xl text-coolGray-500 font-bold">
                    {new Intl.NumberFormat("en-IN", {
                      style: "currency",
                      currency: "INR",
                    }).format(
                      Math.floor(
                        (marketValue * (100 - Math.round(progress))) / 10000000
                      )
                    ) + " Cr"}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class=" px-4 mx-auto absolute bottom-56 md:left-[540px] max-md:left-[510px] ">
        <div className="p-3  text-left text-xs font-semibold text-[#313131]">
          <div className="flex items-center gap-2 ">
            <div className="w-2 h-2 rounded-full bg-sky-300 shadow-[0px_0px_0px_1px_#718096]"></div>
            Low per capita GDP
          </div>
          <div className="flex items-center gap-2 mt-1">
            <div className="w-2 h-2 rounded-full bg-sky-500 shadow-[0px_0px_0px_1px_#718096]"></div>
            High per capita GDP
          </div>
          <div className="flex items-center gap-2 mt-2">
            India's GDP growth: 6-7% per year
          </div>
          
        </div>
      </div>
    </div>
                </div>
              </div>
            </div>

          
    </div>
  );
};

export default DraggableCircularProgressBar;
