import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../PopUp1/ConfirmPopUp.css";
import CheckAuth from "../../components/ServiceFunctions/CheckAuth";
import secureLocalStorage from "react-secure-storage";
import Loader2 from "../Loaders/Loader2";

function BidConfirmationBox({
  formName,
  BidUnit,
  Index,
  OldBidUnit,
  Time,
  PerUnit,
  OrderNo,
  Product,
  Cover,
  CoverPrice,
  Btn,
  bidStatus,
  onClose,
}) {
  const { isAuthenticated, pan, phone, email } = CheckAuth();
  const [series, setSeries] = useState([]);
  const [cities, setCities] = useState([]);
  const [bidComplete, setBidComplete] = useState(false);
  const [checked, setChecked] = useState(false);
  const [newOrderNo, setNewOrderNo] = useState("");
  const [newBidStatus, setNewBidStatus] = useState("");
  const [loaderComplete, setLoaderComplete] = useState(false);
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const storedData2 = secureLocalStorage.getItem("couponData");
  const couponData = JSON.parse(storedData2);
  const storedData1 = secureLocalStorage.getItem("kycData");
  const kycData = JSON.parse(storedData1);
  const storedData3 = secureLocalStorage.getItem("blockchainData");
  const blockchainData = JSON.parse(storedData3);
  const relationshipDate = kycData?.["Relationship_start"];
  const storedData4 = secureLocalStorage.getItem("ownershipData");
  const ownershipData = JSON.parse(storedData4);

  function getHoldingPeriod(productName, category) {
    if (category === "Index") {
      var Days = [];
      var residex_units_calculated = [];
      const sortedCouponData = { pan: couponData?.pan };
      if (couponData) {
        const dateKeys = Object.keys(couponData).filter((key) => key !== "pan");
        const updatedDateKeys = dateKeys.map((key) => {
          const date = new Date(key);
          const options = { day: "2-digit", month: "2-digit", year: "2-digit" };
          const formattedDate = date.toLocaleDateString("en-IN", options);
          sortedCouponData[formattedDate] = couponData[key];
          return formattedDate;
        });
        updatedDateKeys.sort((a, b) => {
          const dateA = new Date(a);
          const dateB = new Date(b);
          return dateA - dateB;
        });
        Object.entries(sortedCouponData).forEach(([key, value], index) => {
          if (key !== "pan") {
            let totalBuy = 0;
            let totalSell = 0;
            let totalSum = 0;
            let totalFutures = 0;
            let totalIndexes = 0;
            if (value && value.RESIDEX) {
              totalFutures += value.RESIDEX.Futures || 0;
              totalIndexes += value.RESIDEX.Index || 0;
            }
            const startDate = new Date(relationshipDate);
            const [day, month, year] = key.split("/");
            const endDate = new Date(`20${year}-${month}-${day}`);
            const today = new Date();
            //  calculating days
            const timeDifference = today.getTime() - endDate.getTime();
            const daysDifference =
              timeDifference > 0
                ? Math.floor(timeDifference / (1000 * 60 * 60 * 24))
                : 0;

            Days.push({ [`D${index}`]: daysDifference / 91 });
            //
            function isDateInRange(date, startDate, endDate) {
              const dateStart = new Date(date.setHours(0, 0, 0, 0));
              const start = new Date(startDate.setHours(0, 0, 0, 0));
              const end = new Date(endDate.setHours(0, 0, 0, 0));

              return dateStart >= start && dateStart <= end;
            }

            blockchainData.filter((item, index) => {
              const itemDate = new Date(item.Timestamp);
              if (
                item.Buyer === couponData.pan &&
                item["Security"] === "RESIDEX City " + productName &&
                isDateInRange(itemDate, startDate, endDate)
              ) {
                totalBuy += item["Units"];
              }
            });

            blockchainData.filter((item, index) => {
              const itemDate = new Date(item.Timestamp);
              if (
                item.Seller === couponData.pan &&
                item["Security"] === "RESIDEX City " + productName &&
                isDateInRange(itemDate, startDate, endDate)
              ) {
                totalSell += item["Units"];
              }
            });
            totalSum = totalBuy - totalSell;
            if (totalSum !== 0) {
              residex_units_calculated.push({ [`X${index}`]: totalSum });
            }
          }
        });
      }

      let sumProd = 0;
      if (Days.length > 0 && Days.length === residex_units_calculated.length) {
        for (let i = 0; i < Days.length; i++) {
          const dayValue = Object.values(Days[i])[0];
          const residexValue = Object.values(residex_units_calculated[i])[0];
          sumProd += dayValue * residexValue;
        }
        return sumProd / ownershipData?.RESIDEX?.[productName]?.["Index"];
      } else {
        return 0;
      }
    } else {
      const today = new Date();
      const currentYear = today.getFullYear();
      let endDate;
      const currentMonth = today.getMonth() + 1;

      if (currentMonth >= 4 && currentMonth <= 6) {
        // Q1: April to June
        endDate = new Date(currentYear, 5, 30); // June 30
      } else if (currentMonth >= 7 && currentMonth <= 9) {
        // Q2: July to September
        endDate = new Date(currentYear, 8, 30); // September 30
      } else if (currentMonth >= 10 && currentMonth <= 12) {
        // Q3: October to December
        endDate = new Date(currentYear, 11, 31); // December 31
      } else {
        // Q4: January to March (of the next year)
        endDate = new Date(currentYear + 1, 2, 31); // March 31 (next year)
      }

      // Calculate the time difference
      const timeDifference = endDate.getTime() - today.getTime();
      const daysDifference =
        timeDifference > 0
          ? Math.floor(timeDifference / (1000 * 60 * 60 * 24))
          : 0;
      return daysDifference / 91;
    }
  }

  function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(";").shift();
  }

  // crash page
  const blacklistRefreshToken = () => {
    setLoader(true);
    if (pan) {
      const refreshToken = getCookie("refresh_token");
      if (refreshToken) {
        fetch(`https://api-scdndestates.com/api/blacklist-refresh-token/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            refresh_token: refreshToken,
            email,
            phone,
          }),
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error("Failed to logout");
            }
            return response.json();
          })
          .then((response) => {
            if (response.success === "Refresh token blacklisted") {
              document.cookie =
                "access_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
              document.cookie =
                "refresh_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
              navigate("/userLost");
            }
          })
          .catch((error) => {
            // console.error("Error during logout:", error);
          });
      }
    }
  };
  // crash page

  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/userLost");
      return; // Prevent further execution if not authenticated
    }
    if (pan && isAuthenticated) {
      fetch(`https://api-scdndestates.com/api/primary-bid-box/`, {
        method: "POST",
        body: JSON.stringify({
          phone: phone,
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Failed to fetch data");
          }
          return response.json();
        })
        .then((data) => {
          setSeries(data.data);
          setCities(data.data2);
        })
        .catch((error) => {
          // console.error("Error: ", error);
        });
    }
  }, [pan, isAuthenticated]);

  const handleSubmit = (isAuthenticated) => {
    if (!isAuthenticated) {
      navigate("/userLost");
      return; // Prevent further execution if not authenticated
    }
    if (isAuthenticated) {
      if (checked === true) {
        setBidComplete(true);
        fetch(`https://api-scdndestates.com/api/sell-buy-order/`, {
          method: "POST",
          body: JSON.stringify({
            pan: pan,
            phone: phone,
            Action: Btn === 1 ? "Buy" : Btn === 0 && "Sell",
            Unit: BidUnit,
            OldUnit: OldBidUnit,
            Cover: CoverPrice,
            PerUnit: PerUnit,
            Security: Product,
            formname: formName,
            Timestamp: Time,
            OrderId: OrderNo,
            Status: bidStatus,
          }),
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error("Failed to fetch data");
            }
            return response.json();
          })
          .then((data) => {
            if (data.data2 === "Crash Page") {
              blacklistRefreshToken();
            } else {
              const loader = document.querySelector(".loader2");
              if (loader) {
                loader.classList.add("complete2");
                setLoaderComplete(true);
                setNewOrderNo(data.id);
                setNewBidStatus(data.status);
              }
              secureLocalStorage.setItem("tempData", JSON.stringify(data.data));
            }
          })
          .catch((error) => {
            // console.error("Error: ", error);
          });
      } else {
        alert("Tick the CheckBox to confirm the order.");
      }
    }
  };

  function getMaturityDate(series, productName) {
    const p = productName.split(" ").pop();
    const productData = series.find((item) => Object.keys(item)[0] === p);
    return productData ? productData[p]["Maturity Date"] : "";
  }

  function getCouponDate(series, productName) {
    const p = productName.split(" ").pop();
    const productData = series.find((item) => Object.keys(item)[0] === p);
    const couponIssue = productData ? productData[p]["Coupon post reset"] : "";
    if (typeof couponIssue === "number") {
      const formattedCouponIssue = couponIssue.toFixed(2);
      const [integerPart, decimalPart] = formattedCouponIssue.split(".");
      if (parseInt(decimalPart) === 0) {
        return integerPart;
      }
      return formattedCouponIssue;
    }
    return couponIssue;
  }

  function getCouponIssue(series, productName) {
    const p = productName.split(" ").pop();
    const productData = series.find((item) => Object.keys(item)[0] === p);
    const couponIssue = productData ? productData[p]["Coupon at Issue"] : "";
    if (typeof couponIssue === "number") {
      const formattedCouponIssue = couponIssue.toFixed(2);
      const [integerPart, decimalPart] = formattedCouponIssue.split(".");
      if (parseInt(decimalPart) === 0) {
        return integerPart;
      }
      return formattedCouponIssue;
    }
    return couponIssue;
  }

  function getCAGR(cities, productName) {
    // Extract the product name without spaces
    const p = productName.slice(13);
    const productData = cities.find((item) => Object.keys(item)[0] === p);
    const cagr = productData ? productData[p]["CAGR"] : "";

    if (typeof cagr === "number") {
      const formattedCAGR = cagr.toFixed(2);
      const [integerPart, decimalPart] = formattedCAGR.split(".");
      if (parseInt(decimalPart) === 0) {
        return integerPart;
      }
      return formattedCAGR;
    }
    return cagr;
  }

  return (
    <>
      {bidComplete ? (
        <>
          <div className="frame2">
            <div className={`loader2 ${loaderComplete ? "complete2" : ""}`}>
              <div className="line2"></div>
              <div className="line2"></div>
              <span className="tick2"></span>
            </div>
            {loaderComplete && (
              <h6 className="confirm-text font-semibold text-sm lg:text-lg w-[300px] lg:w-[700px] mb-3">
                {newBidStatus === "Processing" ? (
                  <>
                    Your Order ID {newOrderNo} has been successfully sent for
                    Processing. Please go to Open Orders to Cancel or Modify.
                  </>
                ) : (
                  <>
                    Your Order ID {newOrderNo} has been successfully{" "}
                    {newBidStatus}. Trading window will be closed at 18:30 hours
                    (6:30 pm IST).
                  </>
                )}
              </h6>
            )}
            {loaderComplete && (
              <button
                className="cursor-pointer flex  w-40 h-8 md:h-10  items-center  justify-center rounded-md border-2 hover:shadow-[inset_0px_0px_2px_0px_#5ab2ff] border-[#40d12c] px-2 py-1 text-xs md:text-sm font-semibold leading-6 duration-300 text-[#212121] shadow-sm bg-white hover:bg-[#ddfddd] z-10 "
                onClick={() => {
                  setChecked(false);
                  setBidComplete(false);
                  onClose();
                  window.location.reload();
                }}
              >
                Go Back
              </button>
            )}
          </div>
        </>
      ) : (
        <div className="demo1-container">
          {loader && <Loader2 />}
          <div className="border bid-verify rounded bg-light border-primary pop-up-animation p-0">
            <div className="row m-0 p-0 mb-2 bg-primary">
              <h6 className="text-light  p-2 px-4">Bid Order Confirmation</h6>
            </div>
            <div className="row m-0 px-4 py-2 border-bottom">
              <h6 className="m-0 p-0 mb-2">
                Please verify the specific details of your Order before
                submission. Orders not cancelled before 1800 hours (6 pm IST)
                will be automatically Executed:
              </h6>
              <table className="table-responsive table table-hover table-borderless table-sm">
                <tbody>
                  <tr style={{ background: "#54b4d324" }}>
                    <td className="font-size1">Security Name</td>
                    <td className="font-size1">{Product}</td>
                  </tr>
                  <tr>
                    {Product.slice(0, 4) === "WALR" ? (
                      <>
                        <td className="font-size1">Maturity Date</td>
                        <td className="font-size1">
                          {getMaturityDate(series, Product)}
                        </td>
                      </>
                    ) : (
                      <>
                        <td className="font-size1">Holding Period</td>
                        <td className="font-size1">
                          {getHoldingPeriod(
                            Product.slice(13),
                            CoverPrice > 0 ? "Futures" : "Index"
                          ).toFixed(2)}
                        </td>
                      </>
                    )}
                  </tr>
                  <tr style={{ background: "#54b4d324" }}>
                    {Product.slice(0, 4) === "WALR" ? (
                      <>
                        <td className="font-size1">Coupon at Issue</td>
                        <td className="font-size1">
                          {getCouponIssue(series, Product)}
                        </td>
                      </>
                    ) : (
                      <>
                        <td className="font-size1">CAGR</td>
                        <td className="font-size1">
                          {getCAGR(cities, Product)}
                        </td>
                      </>
                    )}
                  </tr>
                  <tr>
                    {Product.slice(0, 4) === "WALR" ? (
                      <>
                        <td className="font-size1">Coupon Post Reset</td>
                        <td className="font-size1">
                          {getCouponDate(series, Product)}
                        </td>
                      </>
                    ) : (
                      <>
                        <td className="font-size1">Latest Nav</td>
                        <td className="font-size1">
                          {PerUnit.toLocaleString("en-IN", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                        </td>
                      </>
                    )}
                  </tr>
                  <tr style={{ background: "#54b4d324" }}>
                    <td className="font-size1">Bid Unit</td>
                    <td className="font-size1">{BidUnit}</td>
                  </tr>
                  <tr>
                    <td className="font-size1">Unit Price</td>
                    <td className="font-size1">
                      {PerUnit.toLocaleString("en-IN", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </td>
                  </tr>
                  <tr style={{ background: "#54b4d324" }}>
                    <td className="font-size1">
                      {Product.slice(0, 4) === "WALR" ? (
                        <>Downside Cover</>
                      ) : (
                        <>Instrument Class</>
                      )}
                    </td>
                    <td className="font-size1">
                      {Cover.charAt(0).toUpperCase() + Cover.slice(1)}
                    </td>
                  </tr>
                  <tr>
                    <td className="font-size1">
                      {Product.slice(0, 4) === "WALR" ? (
                        <>Option Price</>
                      ) : (
                        <>3 mth Premium</>
                      )}
                    </td>
                    <td className="font-size1">
                      {parseFloat(CoverPrice).toFixed(2)}
                    </td>
                  </tr>
                  <tr
                    style={{ background: "#54b4d324" }}
                    className="border-bottom"
                  >
                    <td className="font-size1">Total Bid Amount</td>
                    <td className="font-size1">
                      {Cover.charAt(0).toUpperCase() + Cover.slice(1) ===
                      "Futures"
                        ? (
                            BidUnit *
                            (PerUnit + CoverPrice) *
                            1000
                          ).toLocaleString("en-In", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })
                        : (BidUnit * (PerUnit + CoverPrice)).toLocaleString(
                            "en-In",
                            {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }
                          )}
                    </td>
                  </tr>
                </tbody>
              </table>
              <div className="d-flex align-items-start my-1 mx-0 p-0 font-size1">
                <input
                  type="checkbox"
                  value={checked}
                  className="mt-1"
                  onChange={(e) => setChecked(!checked)}
                  name="check1"
                  id="check1"
                  required
                />
                <label htmlFor="check1" className="ms-1">
                  By checking this box, I authorize SCDND to process my DeAs
                  transaction by Smart Contract on the blockchain.
                </label>
              </div>
            </div>
            <div className="d-flex justify-content-end p-3 align-items-center">
              <button
                className={`${
                  Btn === 1 ? "btn-success" : Btn === 0 && "btn-danger"
                } btn btn-sm`}
                onClick={() => {
                  handleSubmit(isAuthenticated);
                }}
              >
                Confirm Order
              </button>
              <button
                type="button"
                className="btn btn-sm btn-outline-secondary ms-2"
                data-mdb-ripple-init
                data-mdb-ripple-color="dark"
                onClick={() => {
                  setChecked(false);
                  onClose();
                }}
              >
                Go Back
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default BidConfirmationBox;
