import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { TiTick } from "react-icons/ti";
import { FaArrowCircleRight } from "react-icons/fa";
import { MdOutlineArrowOutward } from "react-icons/md";
import { HiCurrencyRupee } from "react-icons/hi";
import { IoCalendarSharp } from "react-icons/io5";
import { IoIosCloseCircle } from "react-icons/io";
import "./Content.css";

function Content() {
  const [fromDate, setFromDate] = useState("2013-11");
  const [toDate, setToDate] = useState("");
  const [fromDate2, setFromDate2] = useState("2013-11");
  const [toDate2, setToDate2] = useState("");
  const [amount2, setAmount2] = useState("100000");
  const [show, setShow] = useState("");
  const popupRef = useRef(null);
  const navigate = useNavigate();
  const [series, setSeries] = useState([]);
  const [cities, setCities] = useState([]);
  const [dataFetched, setDataFetched] = useState(false);

  useEffect(() => {
    fetch(`https://api-scdndestates.com/api/primary-bid-box/`, {
      method: "POST",
      body: JSON.stringify({}),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        return response.json();
      })
      .then((data) => {
        setSeries(data.data);
        setCities(data.data2);
        setDataFetched(true);
      })
      .catch((error) => {
        // console.error("Error: ", error);
      });
  }, [dataFetched]);

  document.addEventListener("DOMContentLoaded", function () {
    const wordsElement = document.querySelector(".slider-words");
    if (wordsElement) {
      wordsElement.style.animationPlayState = "paused"; // Pause animation
      wordsElement.offsetHeight; // eslint-disable-line no-unused-expressions
      wordsElement.style.animationPlayState = "running"; // Resume animation
    }
  });

  const categories = series?.map((item) => {
    const seriesName = Object.keys(item)[0];
    const seriesDetails = item[seriesName];
    return {
      name: seriesName,
      pricePerUnit: 1000 + seriesDetails["Option Price"],
      couponAtIssue: seriesDetails["Coupon at Issue"],
    };
  });

  const categories2 = cities?.map((item) => {
    const citiesName = Object?.keys(item)[0];
    const citiesDetails = item[citiesName];
    return {
      name: citiesName,
      pricePerUnit: citiesDetails["Price"],
      couponAtIssue: citiesDetails["CAGR"],
      threeMonthPremium: citiesDetails["3 mth Premium"],
    };
  });

  const submitForm = () => {
    const graphInput = { show, fromDate, toDate, amount2 };
    if (show === "WALR") {
      navigate("/walrbonds/performance", { state: { graphInput } });
    }
    if (show === "RESIDEX") {
      navigate("/residexbonds/performance", { state: { graphInput } });
    }
  };

  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setShow("");
    }
  };

  useEffect(() => {
    fetch(
      `https://api-scdndestates.com/api/get-start-and-end-date/?product=${show}`
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        return response.json();
      })
      .then((data) => {
        setToDate2(data["end"]);
        setToDate(data["end"]);
      })
      .catch((error) => {
        // console.error("Error: ", error);
      });
    if (show === "WALR") {
      setFromDate2("2013-11");
      setFromDate("2013-11");
    } else if (show === "RESIDEX") {
      setFromDate2("2010-04");
      setFromDate("2010-04");
    }

    if (show !== "") {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [show]);

  return (
    <div className="relative">
      <div className="w-[90%] mx-auto pb-4 text-black">
        {/* Heading */}

        {/* First Section */}
        <div className="flex flex-wrap justify-around items-center  product-section">
          <div className="w-full lg:w-[42%] flex flex-col items-center justify-center">
            <a
              href="walrbonds/performance"
              className="w-full flex items-center justify-center"
            >
              <video
                key="1"
                className="w-[520px]"
                src="/images/walr.webm"
                alt={""}
                autoPlay
                loop
                muted
              />
            </a>
            <h2 className="text-base lg:text-xl font-bold mt-2.5 bg-[] px-3 py-1 text-center ">
              WALR (fixed income)
            </h2>
          </div>

          <div className="border shadow-md p-0 rounded-lg w-full h-fit lg:w-[50%] gap-3 flex flex-col justify-between ">
            <div className="gap-3 flex flex-col justify-between">
              <h3 className="text-xl md:text-xl  px-4 pt-4 flex flex-wrap justify-start md:justify-between items-center gap-2.5 xl:gap-4 pb-2">
                <div className="text-lg 2xl:text-xl slice ">
                  <h2 className="font-semibold  flex items-center justify-center h-[30px] ">
                    Series
                    <span className="slider-loader ms-1 w-[100px]">
                      <ul className="slider-words">
                        {categories?.map((item, index) => (
                          <li
                            className="slider-word  flex items-center justify-center text-black text-lg 2xl:text-xl"
                            key={index}
                          >
                            {item.name}
                          </li>
                        ))}
                      </ul>
                    </span>
                  </h2>
                </div>

                <div className="flex border rounded-lg py-0.5">
                  <span className="transition-style flex items-center gap-2.5">
                    <p className="font-semibold text-blue-700 flex items-center text-lg 2xl:text-xl">
                      
                      <span className="slider-loader">
                        <div className="slider-words w-[80px] text-lg 2xl:text-xl">
                          {categories?.map((item, index) => (
                            <span className="slider-word flex items-center justify-center" key={index}>
                              <HiCurrencyRupee  />{item.pricePerUnit}
                            </span>
                          ))}
                        </div>
                      </span>
                    </p>

                    <div className="flex gap-2 items-center shadow-sm px-1 py-0.5 rounded-md text-xl bg-indigo-200 text-black w-[80px]">
                      <span className="slider-loader font-semibold">
                        <div className="slider-words  text-lg 2xl:text-xl">
                          {categories?.map((item, index) => (
                            <span className="slider-word pl-2 flex items-center justify-center" key={index}>
                              {item.couponAtIssue.toFixed(2)}%
                            </span>
                          ))}
                        </div>
                      </span>
                    </div>
                    <span className="slider-loader font-semibold">
                      <ul className="slider-words w-[60px]">
                        {categories?.map((item, index) => (
                          <li className="slider-word  flex items-center " key={index}>
                            {categories[index - 1]?.couponAtIssue <
                            item.couponAtIssue ? (
                              <MdOutlineArrowOutward
                                className="text-green-600 text-sm 2xl:text-lg"
                                size={30}
                              />
                            ) : (
                              <MdOutlineArrowOutward
                                className="origin-center rotate-90 text-red-600 text-sm 2xl:text-lg"
                                size={30}
                              />
                            )}
                          </li>
                        ))}
                      </ul>
                    </span>
                  </span>
                </div>

                <div className="flex gap-2 text-xl xl:text-2xl font-semibold">
                  <a
                    href="/PrimaryMarket/walr"
                    className="bg-success px-2.5 py-1 rounded-md text-sm md:text-base text-white hover:scale-105 duration-200"
                  >
                    Buy
                  </a>
                  <a
                    href="/TradingPortal"
                    className="bg-danger px-2.5 py-1 rounded-md text-sm md:text-base text-white hover:scale-105 duration-200"
                  >
                    Sell
                  </a>
                </div>
              </h3>
              <hr />
              {/* Bond Details */}
              <div className="flex flex-wrap py-2.5 justify-between gap-4 xl:gap-28 px-1 mt-3 w-[85%]">
                <div className="">
                  <ul className="flex flex-col font-semibold gap-4 text-sm 2xl:text-base">
                    <li>
                      <h6 className="flex items-center gap-1">
                        <TiTick />
                        Benchmarked to RBI rates
                      </h6>
                    </li>
                    <li>
                      <h6 className="flex items-center gap-1">
                        <TiTick />
                        Prices 100% transparent
                      </h6>
                    </li>
                    <li>
                      <h6 className="flex items-center gap-1">
                        <TiTick />
                        Interest only rises, never falls
                      </h6>
                    </li>
                  </ul>
                </div>

                <div className="">
                  <ul className="flex flex-col font-semibold gap-4 text-sm 2xl:text-base">
                    <li>
                      <h6 className="flex items-center gap-1">
                        <TiTick />
                        Client-funds are Secured
                      </h6>
                    </li>
                    <li>
                      <h6 className="flex items-center gap-1">
                        <TiTick />
                        Management Fee is 0.5%
                      </h6>
                    </li>
                    <li>
                      <h6 className="flex items-center gap-1">
                        <TiTick />
                        Zero Liquidity-risk
                      </h6>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="flex flex-wrap px-4 pb-4 md:justify-between items-center mt-5 gap-3 lg:gap-0 slice">
              <div className="font-semibold rounded-md">
                <a
                  href="walrbonds/overview"
                  className="flex px-2 py-1 rounded-md items-center shadow-sm gap-2 duration-200 text-sm 2xl:text-base"
                >
                  Explore More{" "}
                  <FaArrowCircleRight className="text-xs lg:text-sm" />
                </a>
              </div>

              <div className="font-semibold rounded-md">
                <a
                  className="flex px-2 py-1 rounded-md items-center shadow-sm gap-2 duration-200 text-sm 2xl:text-base cursor-pointer"
                  onClick={() => setShow("WALR")}
                >
                  Check Performance{" "}
                  <FaArrowCircleRight className="text-xs lg:text-sm" />
                </a>
              </div>
            </div>
          </div>
        </div>

        <hr className="lg:my-10" />

        {/* Second Section */}
        <div className="flex flex-row-reverse flex-wrap justify-around mt-10 product-section">
          <div className="w-full lg:w-[42%] flex flex-col items-center justify-center">
            <a
              href="residexbonds/performance/"
              className="w-full flex items-center justify-center"
            >
              <video
                key="1"
                className="w-[520px]"
                src="/images/residex.webm"
                alt={""}
                autoPlay
                loop
                muted
              />
            </a>
            <h2 className="text-base lg:text-xl font-bold mt-2.5 bg-[] px-3 py-1 text-center ">
              RESIDEX (real estate)
            </h2>
          </div>

          <div className="border shadow-md p-0 rounded-lg w-full h-fit lg:w-[56%] xl:w-[57%] 2xl:w-[55%] gap-3 flex flex-col justify-between">
          <div className="gap-3 flex flex-col justify-between">
              <h3 className="text-xl md:text-xl  px-4 pt-4 flex flex-wrap justify-start md:justify-between items-center gap-2.5 xl:gap-4 pb-2">
                <div className="text-lg 2xl:text-xl slice ">
                  <h2 className="font-semibold  flex items-center justify-center h-[30px] ">
                    City
                    <span className="slider-loader ms-1 w-[130px]">
                      <ul className="slider-words">
                        {categories2?.map((item, index) => (
                          <li
                            className="slider-word  flex items-center justify-start text-black text-lg 2xl:text-xl"
                            key={index}
                          >
                            {item.name}
                          </li>
                        ))}
                      </ul>
                    </span>
                  </h2>
                </div>

                <div className="flex border rounded-lg py-0.5">
                  <span className="transition-style flex items-center gap-2.5">
                    <p className="font-semibold text-blue-700 flex items-center text-lg 2xl:text-xl">
                      
                      <span className="slider-loader">
                        <div className="slider-words w-[80px] text-lg 2xl:text-xl">
                          {categories2?.map((item, index) => (
                            <span className="slider-word flex items-center justify-center" key={index}>
                              <HiCurrencyRupee  />{item.pricePerUnit}
                            </span>
                          ))}
                        </div>
                      </span>
                    </p>

                    <div className="flex gap-2 items-center shadow-sm px-1 py-0.5 rounded-md text-xl bg-indigo-200 text-black w-[80px]">
                      <span className="slider-loader font-semibold">
                        <div className="slider-words  text-lg 2xl:text-xl">
                          {categories2?.map((item, index) => (
                            <span className="slider-word pl-2 flex items-center justify-center" key={index}>
                              {item.couponAtIssue.toFixed(2)}%
                            </span>
                          ))}
                        </div>
                      </span>
                    </div>
                    <span className="slider-loader font-semibold">
                      <ul className="slider-words w-[60px]">
                        {categories2?.map((item, index) => (
                          <li className="slider-word  flex items-center " key={index}>
                            {categories2[index - 1]?.couponAtIssue <
                            item.couponAtIssue ? (
                              <MdOutlineArrowOutward
                                className="text-green-600 text-sm 2xl:text-lg"
                                size={30}
                              />
                            ) : (
                              <MdOutlineArrowOutward
                                className="origin-center rotate-90 text-red-600 text-sm 2xl:text-lg"
                                size={30}
                              />
                            )}
                          </li>
                        ))}
                      </ul>
                    </span>
                  </span>
                </div>

                <div className="flex gap-2 text-xl xl:text-2xl font-semibold">
                  <a
                    href="/PrimaryMarket/walr"
                    className="bg-success px-2.5 py-1 rounded-md text-sm md:text-base text-white hover:scale-105 duration-200"
                  >
                    Buy
                  </a>
                  <a
                    href="/TradingPortal"
                    className="bg-danger px-2.5 py-1 rounded-md text-sm md:text-base text-white hover:scale-105 duration-200"
                  >
                    Sell
                  </a>
                </div>
              </h3>
              <hr />
              {/* Bond Details */}
              <div className="flex flex-wrap py-2.5 justify-between gap-4 xl:gap-28 px-1 mt-3 w-[85%]">
                <div className="">
                  <ul className="flex flex-col font-semibold gap-4 text-sm 2xl:text-base">
                    <li>
                      <h6 className="flex items-center gap-1">
                        <TiTick />
                        Benchmarked to RBI rates
                      </h6>
                    </li>
                    <li>
                      <h6 className="flex items-center gap-1">
                        <TiTick />
                        Prices 100% transparent
                      </h6>
                    </li>
                    <li>
                      <h6 className="flex items-center gap-1">
                        <TiTick />
                        Interest only rises, never falls
                      </h6>
                    </li>
                  </ul>
                </div>

                <div className="">
                  <ul className="flex flex-col font-semibold gap-4 text-sm 2xl:text-base">
                    <li>
                      <h6 className="flex items-center gap-1">
                        <TiTick />
                        Client-funds are Secured
                      </h6>
                    </li>
                    <li>
                      <h6 className="flex items-center gap-1">
                        <TiTick />
                        Management Fee is 0.5%
                      </h6>
                    </li>
                    <li>
                      <h6 className="flex items-center gap-1">
                        <TiTick />
                        Zero Liquidity-risk
                      </h6>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="flex flex-wrap px-4 pb-4 md:justify-between items-center mt-5 gap-3 lg:gap-0 slice">
              <div className="font-semibold rounded-md">
                <a
                  href="residexbonds/overview"
                  className="flex px-2 py-1 rounded-md items-center shadow-sm gap-2 duration-200 text-sm 2xl:text-base"
                >
                  Explore More{" "}
                  <FaArrowCircleRight className="text-xs lg:text-sm" />
                </a>
              </div>

              <div className="font-semibold rounded-md">
                <a
                  className="flex px-2 py-1 rounded-md items-center shadow-sm gap-2 duration-200 text-sm 2xl:text-base cursor-pointer"
                  onClick={() => setShow("RESIDEX")}
                >
                  Check Performance{" "}
                  <FaArrowCircleRight className="text-xs lg:text-sm" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div>
      <FAQ />
      </div> */}

      {show && (
        <div className="demo1-container text-black p-2">
          <div
            ref={popupRef}
            className="rounded-2xl w-fit bg-white mx-auto my-4 pop-up-animation"
          >
            <h2 className="text-lg font-bold bg-[#0590F8] px-3 py-2 rounded-t-2xl text-white flex items-center justify-between">
              Select Start & End date
              <IoIosCloseCircle
                className="cursor-pointer"
                onClick={() => setShow("")}
              />
            </h2>
            <div className="flex flex-wrap px-3 py-4 gap-4">
              {/* <!-- From Div --> */}
              <div className="flex items-center gap-1 font-semibold w-full sm:w-auto">
                <div>
                  <label
                    htmlFor="from"
                    className="text-light flex flex-col text-base text-black"
                  >
                    From&nbsp;
                    <span className="flex items-center justify-around gap-1 p-2 border-1 rounded-md border-zinc-300 text-zinc-700">
                      <DatePicker
                        className="rounded-md text-black bg-white w-32 text-base outline-none"
                        style={{ backgroundColor: "#623eba" }}
                        name="from"
                        id="from"
                        selected={new Date(fromDate)}
                        onChange={(date) =>
                          setFromDate(date.toISOString().slice(0, 7))
                        }
                        minDate={new Date(fromDate2)}
                        maxDate={new Date(toDate2)}
                        dateFormat="MMMM yyyy"
                        showMonthYearPicker
                      />
                      <IoCalendarSharp className="text-xl" />
                    </span>
                  </label>
                </div>
              </div>
              {/* <!-- To Div --> */}
              <div className="flex items-center gap-2 font-semibold">
                <div>
                  <label
                    htmlFor="to"
                    className="text-light flex flex-col text-base text-black"
                  >
                    To&nbsp;
                    <span className="flex items-center gap-1 p-2  border-1 rounded-md border-zinc-300 text-zinc-700">
                      <DatePicker
                        className="rounded-md text-black bg-white w-32 text-base outline-none"
                        style={{ backgroundColor: "#623eba" }}
                        name="to"
                        id="to"
                        selected={new Date(toDate)}
                        onChange={(date) =>
                          setToDate(date.toISOString().slice(0, 7))
                        }
                        minDate={new Date(fromDate2)}
                        maxDate={new Date(toDate2)}
                        dateFormat="MMMM yyyy"
                        showMonthYearPicker
                      />
                      <IoCalendarSharp className="text-xl" />
                    </span>
                  </label>
                </div>
              </div>
            </div>
            <hr className="mx-3" />
            <div className="flex flex-wrap justify-between items-center px-3 py-3 gap-4">
              {/* <!-- Investment section --> */}
              <div className="flex items-center gap-2 font-semibold">
                <p className="flex items-center gap-1">Investment</p>
                <select
                  name="amount"
                  id="amount"
                  value={amount2}
                  onChange={(e) => setAmount2(e.target.value)}
                  className="border-b-1 border-[#0590F8] rounded outline-none w-full sm:w-auto border-b-2 animate-fade-in-select"
                >
                  <option value="100000">₹ 1,00,000</option>
                  <option value="200000">₹ 2,00,000</option>
                  <option value="300000">₹ 3,00,000</option>
                  <option value="400000">₹ 4,00,000</option>
                  <option value="500000">₹ 5,00,000</option>
                </select>
              </div>
              {/* <!-- Button --> */}
              <div className="slice-btn">
                <button
                  onClick={() => {
                    submitForm();
                  }}
                  className=" duration-200 shadow-sm px-4 py-1.5 rounded-md text-sm text-white font-semibold w-full sm:w-auto"
                >
                  Check
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Content;
